import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  rem,
  Badge,
  SimpleGrid,
  Card,
  useMantineTheme,
  ActionIcon,
  Burger,
} from "@mantine/core";
import { Authenticator } from "@aws-amplify/ui-react";
import {
  IconCheck,
  IconChartBubble,
  IconBugOff,
  IconReportMoney,
  IconBrandTwitter,
  IconBrandLinkedin,
  IconBrandYoutube,
  IconBrandInstagram,
} from "@tabler/icons-react";
import classes from "./../styles/landing.module.css";
import image from "./../assets/landing_graphics.svg";
import logo from "./../assets/CodaHx_logo_blue.svg";
import summary_part from "./../assets/summary_part.svg";
import potential_errors from "./../assets/potential_errors.svg";
import FPL from "./../assets/FPL.svg";
import { useDisclosure } from "@mantine/hooks";
import { motion } from "framer-motion";
import { FaRegCheckCircle } from "react-icons/fa";


const data = [
  {
    title: "About",
    links: [
      { label: "Features", link: "#" },
      { label: "Pricing", link: "#" },
      { label: "Support", link: "#" },
      { label: "Forums", link: "#" },
    ],
  },
  {
    title: "Project",
    links: [
      { label: "Contribute", link: "#" },
      { label: "Media assets", link: "#" },
      { label: "Changelog", link: "#" },
      { label: "Releases", link: "#" },
    ],
  },
  {
    title: "Community",
    links: [
      { label: "Join Discord", link: "#" },
      { label: "Follow on Twitter", link: "#" },
      { label: "Email newsletter", link: "#" },
      { label: "GitHub discussions", link: "#" },
    ],
  },
];

const featuresData = [
  {
    title: "Detailed Bill Summary",
    description:
      "Get clear, itemized breakdowns of your medical bills, ensuring full transparency and understanding of each charge.",
    image: summary_part,
    highlights: [
      "Understand every charge",
      "No hidden fees",
      "Transparent billing",
    ],
  },
  {
    title: "Automatic Error Detection",
    description:
      "Identify and flag potential billing mistakes instantly, helping you avoid unnecessary costs and ensuring accuracy.",
    image: potential_errors,
    highlights: [
      "Spot errors instantly",
      "Save money",
      "Ensure accuracy",
    ],
  },
  {
    title: "Discount Eligibility Screening",
    description:
      "Quickly determine if you qualify for discounts or financial assistance, maximizing your savings on medical expenses.",
    image: FPL,
    highlights: [
      "Find discounts",
      "Financial aid options",
      "Maximize savings",
    ],
  },
];

const mockdata = [
  {
    title: "Clear",
    description:
      "Experience crystal-clear visibility into your medical expenses with detailed, itemized breakdowns. Our app ensures every charge is fully transparent and easy to comprehend. Eliminate the confusion and gain complete confidence in what you’re paying for.",
    icon: IconChartBubble,
  },
  {
    title: "Error Free",
    description:
      "Reliably spot billing mistakes with our advanced, automated error detection feature. Prevent costly errors from slipping through unnoticed. Ensure your bills are impeccably accurate and avoid unnecessary charges.",
    icon: IconBugOff,
  },
  {
    title: "Seamless",
    description:
      "Discover seamless savings with our quick and intuitive discount and financial aid screening. Maximize your benefits and minimize out-of-pocket expenses. Effortlessly find opportunities for financial assistance.",
    icon: IconReportMoney,
  },
];

const navlinks = [
  { link: "/about", label: "Features" },
  { link: "/pricing", label: "Pricing" },
  // { link: "/learn", label: "Learn" },
  // { link: "/community", label: "Community" },
];



function Landing() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState(navlinks[0].link);
  const navigate = useNavigate();

  const theme = useMantineTheme();
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <feature.icon
        style={{ width: rem(50), height: rem(50) }}
        stroke={2}
        color={theme.colors.blue[9]}
      />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component="a"
        href={link.link}
        onClick={(event) => event.preventDefault()}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title2} fw={700}>
          {group.title}
        </Text>
        <Text color="gray.6" size="sm">
          {links}
        </Text>
      </div>
    );
  });

  const items = navlinks.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={classes.linkH}
      data-active={active === link.link || undefined}
      onClick={(event) => {
        event.preventDefault();
        setActive(link.link);
      }}
    >
      {link.label}
    </a>
  ));

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await Authenticator.currentAuthenticatedUser();
        const { username, attributes } = currentUser;
        const userId = attributes.sub;

        setUser({ username, userId });
      } catch (error) {
        console.error("Error fetching user", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleSignupHospital = () => {
    window.location.href =
      "https://docs.google.com/forms/d/e/1FAIpQLScww_uRcXqKLM4aNzn4bWvWUpQM7kEEotAEY2MQIKZaoDH2nw/viewform?usp=sf_link";
  };

  const handleLogin = () => {
    navigate("/protected");
  };

  if (loading) {
    return <p>Loading user information...</p>;
  }

  return (
    <>
      <div className={classes.gradientBackground}>
        <header className={classes.header}>
          <Container size="xl" className={classes.innerH}>
            <Image src={logo} h={"2.5rem"} />
            <Group gap="xl" visibleFrom="xs" mr="lg" pr="lg">
              {items}
              {/* <Button
                variant="outline"
                radius="md"
                color="blue.9"
                onClick={handleLogin}
              >
                Login
              </Button> */}
            </Group>

            <Burger opened={opened} onClick={toggle} hiddenFrom="xs" size="sm" />
          </Container>
        </header>

        <Container size="xl" mb="lg" py="sm">
          <div className={classes.inner}>
            <div className={classes.content}>
              <Text
                className={classes.title} 
                size="5rem"
                fw={900}
                c="blue.9"
              >
                For All Your Medical Billing Needs
              </Text>
              <Text c="dark.4" mt="md" size="xl" fw={500}>
                Take control of your healthcare expenses with our cutting-edge
                features. With our intuitive platform, managing your medical bills
                has never been easier or more transparent
              </Text>

              <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl" color="blue.9">
                    <IconCheck
                      style={{ width: rem(12), height: rem(12) }}
                      stroke={1.5}
                    />
                  </ThemeIcon>
                }
              >
                <List.Item>
                  <b>Detailed Explanations of your Charges</b> - Get clear,
                  itemized breakdowns of your medical bills, ensuring full
                  transparency and understanding of each charge.
                </List.Item>
                <List.Item>
                  <b>Automatically Spot Errors in your Bills</b> - Identify and
                  flag potential billing mistakes instantly, helping you avoid
                  unnecessary costs and ensuring accuracy.
                </List.Item>
                <List.Item>
                  <b>Instant Screening for Discount Eligibility</b> - Quickly
                  determine if you qualify for discounts or financial assistance,
                  maximizing your savings on medical expenses.
                </List.Item>
              </List>

              <Group mt={30} className={classes.group}>
                <Button
                  radius="xl"
                  size="md"
                  variant="filled"
                  color="blue.9"
                  className={classes.control}
                  onClick={handleSignupHospital}
                >
                  Request Access
                </Button>
                <Button
                  variant="default"
                  radius="xl"
                  size="md"
                  color="blue.9"
                  className={classes.control}
                  onClick={() => {
                    window.location.href =
                      "https://drive.google.com/file/d/16BR1IZFZ6UX9G9eujLS554rrg6EE05oi/view?usp=share_link";
                  }}
                >
                  View a Demo
                </Button>
              </Group>
            </div>
            <Image src={image} className={classes.image} />
          </div>
        </Container>
        
        <Container size="xl" pb="xl">
          <Group justify="center">
            <Badge variant="filled" size="lg" color="blue.9">
              Your Best Medical Billing Helper
            </Badge>
          </Group>

          <Title order={2} className={classes.title} ta="center" mt="sm">
            The Better Way to Pay Your Medical Bills
          </Title>

          <Text c="dimmed" className={classes.description} ta="center" mt="md">
            Navigate medical bills confidently — our app helps you identify errors
            and unlock potential savings with just a few questions.
          </Text>

          <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
            {features}
          </SimpleGrid>
        </Container>
        <div style={{ height: "9rem" }} /> {/* Adds extra spacing */}
      </div>


      {/* App demo visualizations */}
      <Container size="xxl" bg="var(--mantine-color-blue-light)">
        <div style={{ height: "9rem" }} />
        <Title
          order={1}
          ta="center" 
          size="4rem" 
          fw={200}
          className={classes.slideInFade}
        >
          Empower Your Medical Billing Experience
        </Title>
        <div style={{ height: "7rem" }} />
        {featuresData.map((feature, itemIndex) => (
          <div className={classes.featureWrapper} key={itemIndex}>
            <div
              className={`${classes.featuresContainer} ${
                itemIndex % 2 === 0 ? classes.rowReverse : ''
              }`}
            >
              {/* Image section */}
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ amount: 0.2 }}
                transition={{
                  duration: 0.8,
                  ease: "easeInOut",
                }}
                className={classes.imageSection}
              >
                <div className={classes.imageWrapper}>
                  <Image
                    src={feature.image}
                    alt="feature-image"
                    className={classes.featureImage}
                  />
                </div>
              </motion.div>
              {/* Text section */}
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ amount: 0.2 }}
                transition={{
                  duration: 1.5,
                  ease: "easeInOut",
                }}
                className={classes.textSection}
              >
                <div className={classes.featureText}>
                  <Title size={40} align="center">{feature.title}</Title>
                  <Text color="dimmed" mt="sm" align="center">
                    {feature.description}
                  </Text>

                  <div className={classes.highlightListWrapper}>
                    <List
                      className={classes.highlightList}
                      mt={20}
                      spacing="sm"
                      size="sm"
                      icon={
                        <ThemeIcon size={20} radius="xl" color="blue.9">
                          <FaRegCheckCircle />
                        </ThemeIcon>
                      }
                    >
                      {feature.highlights?.map((highlight, idx) => (
                        <List.Item key={idx}>{highlight}</List.Item>
                      ))}
                    </List>
                  </div>


                </div>
              </motion.div>
            </div>
          </div>
        ))}
      </Container>

      <Container size="xl" my="xl" py="lg">
        <div className={classes.wrapper3} h={"30rem"}>
          <div className={classes.body3}>
            <Title className={classes.title3}>Wait a minute ...</Title>
            <Text fw={500} fz="lg" mb={5}>
              Get early access to our app!
            </Text>
            <Text fz="sm" c="dimmed">
              You will never miss important product updates, latest news and
              community QA sessions.
            </Text>

            <div className={classes.controls3}>
              {/* <TextInput
                placeholder="Your email"
                classNames={{
                  input: classes.input,
                  root: classes.inputWrapper,
                }}
              /> */}
              <Button
                className={classes.control3}
                color="blue.9"
                onClick={() => {
                  window.location.href =
                    "https://docs.google.com/forms/d/e/1FAIpQLScww_uRcXqKLM4aNzn4bWvWUpQM7kEEotAEY2MQIKZaoDH2nw/viewform?usp=sf_link";
                }}
              >
                Early Access
              </Button>
            </div>
          </div>
          <Image src={image.src} className={classes.image3} />
        </div>
      </Container>

      <footer className={classes.footer}>
        <Container className={classes.inner2}>
          <div className={classes.logo}>
            {/* <MantineLogo size={30} /> */}
            {/* <Image src={logo} className={classes.logoImage} /> */}
            {/* <Text size="xs" c="dimmed" className={classes.description2}>
              For All Your Medical Billing Needs
            </Text> */}
          </div>
          {/* <div className={classes.groups2}>{groups}</div> */}
        </Container>
        <Container className={classes.afterFooter}>
          <Text c="dimmed" size="sm">
            © 2024 CodaHx.co. All rights reserved.
          </Text>

          <Group
            gap={0}
            className={classes.social}
            justify="flex-end"
            wrap="nowrap"
          >
            <ActionIcon size="lg" color="gray" variant="subtle" component="a" href="https://www.linkedin.com/company/codahx/" target="_blank" rel="noopener noreferrer">
              <IconBrandLinkedin
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
            {/* <ActionIcon size="lg" color="gray" variant="subtle">
              <IconBrandYoutube
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
            <ActionIcon size="lg" color="gray" variant="subtle">
              <IconBrandInstagram
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon> */}
          </Group>
        </Container>
      </footer>
    </>
  );
}

export default Landing;
