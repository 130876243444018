import React, { useState, useEffect } from "react";
import { uploadData } from "aws-amplify/storage";
import {
  Paper,
  Title,
  Flex,
  ScrollArea,
  Text,
  Button,
  LoadingOverlay,
  Alert,
  Accordion,
  List,
  Badge,
  Card,
  Group,
  Stack,
  ThemeIcon,
  Box,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { generateClient } from "aws-amplify/api";
import {
  IconAlertCircle,
  IconPercentage,
  IconFileDownload,
  IconChecklist,
  IconCurrencyDollar,
} from "@tabler/icons-react";
import {
  getHospitalDetailsByName,
  getApplicationRequirementsByHospital,
  getFederalPovertyLimitsByCriteria,
  getBillCase,
} from "../../graphql/queries";
import {
  BedrockRuntimeClient,
  InvokeModelCommand,
} from "@aws-sdk/client-bedrock-runtime";
import { jsPDF } from "jspdf";
import { fetchAuthSession } from "aws-amplify/auth";
import { updateCase } from "../../graphql/mutations";

const client = generateClient();

// Helper Functions
const isValidPolicyRange = (policy) => {
  return (
    !isNaN(policy.thresholdBottom) &&
    !isNaN(policy.thresholdTop) &&
    policy.thresholdBottom < policy.thresholdTop
  );
};

const getEligibilityColor = (policy, incomeMultiple) => {
  if (!incomeMultiple) return "gray";
  const multiple = Number(incomeMultiple);
  const bottom = Number(policy.thresholdBottom);
  const top = Number(policy.thresholdTop);

  return multiple >= bottom && multiple <= top ? "green" : "red";
};

const formatRequirements = (requirementString) => {
  try {
    const cleanString = requirementString
      .replace(/\\n/g, " ")
      .replace(/\\/g, "")
      .replace(/^\[/, "")
      .replace(/\]$/, "");
    const reqArray = cleanString.match(/\{[^}]+\}/g) || [];
    return reqArray
      .map((reqStr) => {
        const matches = reqStr.match(/'([^']+)'\s*:\s*'([^']+)'/);
        return matches
          ? { title: matches[1], description: matches[2].trim() }
          : null;
      })
      .filter(Boolean);
  } catch (error) {
    console.error("Error parsing requirements:", error);
    return [];
  }
};

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const getFPLState = (state) => {
  return state === "Hawaii" || state === "Alaska" ? state : "All48";
};

const getQualificationStatus = (multiple, policies) => {
  const numericMultiple = Number(multiple);
  return policies.some(
    (policy) =>
      numericMultiple >= Number(policy.thresholdBottom) &&
      numericMultiple <= Number(policy.thresholdTop)
  )
    ? "Qualified"
    : "Not Qualified";
};

const disputeLetterPrompt = `
Generate a formal dispute letter for medical billing errors. Use the following errors found in the bill: {{ERRORS}}

The letter should:
1. Be professional and courteous
2. Cite specific CPT codes and errors found
3. Request a review and correction
4. Include patient information: {{PATIENT_INFO}}. The date in the patient information is the date that the patient received the Medical Bill.

Format the letter properly with:
- Today's date ( {{TODAY_DATE}} )
- Hospital name and address
- Patient name and details
- Professional closing
- Do not contain information that's not available.
- Do not use placeholders like '[]' in emails. Ensure the email is complete and ready to be sent to the recipient.

Keep the tone professional but firm.
`;

const Step4 = ({ preliminaryInfo, billAnalysis }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hospitalPolicies, setHospitalPolicies] = useState([]);
  const [qualificationStatus, setQualificationStatus] = useState(null);
  const [incomeMultiple, setIncomeMultiple] = useState(0);
  const [applicationRequirements, setApplicationRequirements] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [fplBaseIncome, setFplBaseIncome] = useState(null);
  // Update the generateDisputeLetter function in Step4
  const generateDisputeLetter = async () => {
    try {
      setLoading(true);

      // First, get the caseId from the first bill
      const firstBill = billAnalysis?.[0];
      if (!firstBill) {
        throw new Error("No bills available");
      }

      // Get the caseId
      const apiClient = generateClient();
      const caseResult = await apiClient.graphql({
        query: getBillCase,
        variables: {
          billName: firstBill.billName,
        },
      });

      const caseId = caseResult.data.listBills.items[0]?.caseId;
      if (!caseId) {
        throw new Error("Could not find associated case");
      }

      console.log("Found caseId:", caseId);

      const errors = billAnalysis
        ?.map((bill) => ({
          billName: bill.billName,
          errors: bill.result.filter((item) => item.Error !== "N/A"),
        }))
        .filter((bill) => bill.errors.length > 0);

      const patientInfo = {
        name: preliminaryInfo.name,
        hospital: preliminaryInfo.hospital,
        date: preliminaryInfo.date,
      };

      const currentDate = new Date();
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formatted_today_Date = currentDate.toLocaleDateString(
        undefined,
        options
      );

      const prompt = disputeLetterPrompt
        .replace("{{ERRORS}}", JSON.stringify(errors))
        .replace("{{PATIENT_INFO}}", JSON.stringify(patientInfo))
        .replace("{{TODAY_DATE}}", formatted_today_Date);

      const { credentials } = await fetchAuthSession();
      const client = new BedrockRuntimeClient({
        region: "us-west-2",
        credentials: credentials,
      });

      const input = {
        modelId: "anthropic.claude-3-5-sonnet-20241022-v2:0",
        contentType: "application/json",
        accept: "application/json",
        body: JSON.stringify({
          anthropic_version: "bedrock-2023-05-31",
          max_tokens: 2500,
          messages: [
            { role: "user", content: [{ type: "text", text: prompt }] },
          ],
        }),
      };

      const command = new InvokeModelCommand(input);
      const result = await client.send(command);
      const responseBody = JSON.parse(new TextDecoder().decode(result.body));
      const letterContent = responseBody.content[0].text;

      // Generate PDF
      const doc = new jsPDF();
      doc.setFontSize(12);
      doc.text(letterContent, 20, 20, { maxWidth: 170 });

      // Convert PDF to blob
      const pdfBlob = doc.output("blob");

      // Create unique filename
      const timestamp = Date.now();
      const fileName = `public/dispute-letters/${preliminaryInfo.name.replace(
        /\s+/g,
        "-"
      )}-${timestamp}.pdf`;

      // Upload to S3
      const uploadResult = await uploadData({
        key: fileName,
        data: pdfBlob,
        options: {
          contentType: "application/pdf",
        },
      }).result;

      console.log("Upload successful:", uploadResult);

      // Update Case record with the file path
      await apiClient.graphql({
        query: updateCase,
        variables: {
          input: {
            id: caseId,
            disputeLetterUrl: fileName,
            disputeLetterDate: new Date().toISOString(),
          },
        },
      });

      // Allow immediate download
      doc.save("dispute-letter.pdf");

      notifications.show({
        title: "Success",
        message: "Dispute letter generated and saved successfully",
        color: "green",
      });
    } catch (error) {
      console.error("Error in dispute letter generation:", error);
      notifications.show({
        title: "Error",
        message: error.message || "Failed to generate dispute letter",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };
  // const generateDisputeLetter = async () => {
  //   try {
  //     setLoading(true);
  //     const errors = billAnalysis
  //       ?.map((bill) => ({
  //         billName: bill.billName,
  //         errors: bill.result.filter((item) => item.Error !== "N/A"),
  //       }))
  //       .filter((bill) => bill.errors.length > 0);

  //     console.log(preliminaryInfo);
  //     const patientInfo = {
  //       name: preliminaryInfo.name,
  //       hospital: preliminaryInfo.hospital,
  //       date: preliminaryInfo.date,
  //     };

  //     const currentDate = new Date();
  //     const options = { year: 'numeric', month: 'long', day: 'numeric' };
  //     const formatted_today_Date = currentDate.toLocaleDateString(undefined, options);

  //     const prompt = disputeLetterPrompt
  //       .replace("{{ERRORS}}", JSON.stringify(errors))
  //       .replace("{{PATIENT_INFO}}", JSON.stringify(patientInfo))
  //       .replace("{{TODAY_DATE}}", formatted_today_Date);

  //     const { credentials } = await fetchAuthSession();
  //     const client = new BedrockRuntimeClient({
  //       region: "us-west-2",
  //       credentials: credentials,
  //     });

  //     const input = {
  //       modelId: "anthropic.claude-3-5-sonnet-20241022-v2:0",
  //       contentType: "application/json",
  //       accept: "application/json",
  //       body: JSON.stringify({
  //         anthropic_version: "bedrock-2023-05-31",
  //         max_tokens: 2500,
  //         messages: [
  //           { role: "user", content: [{ type: "text", text: prompt }] },
  //         ],
  //       }),
  //     };

  //     const command = new InvokeModelCommand(input);
  //     const result = await client.send(command);
  //     const responseBody = JSON.parse(new TextDecoder().decode(result.body));
  //     const letterContent = responseBody.content[0].text;

  //     const doc = new jsPDF();
  //     doc.setFontSize(12);
  //     doc.text(letterContent, 20, 20, { maxWidth: 170 });
  //     doc.save("dispute-letter.pdf");

  //     notifications.show({
  //       title: "Success",
  //       message: "Dispute letter generated successfully",
  //       color: "green",
  //     });
  //   } catch (error) {
  //     console.error("Error generating letter:", error);
  //     notifications.show({
  //       title: "Error",
  //       message: "Failed to generate dispute letter",
  //       color: "red",
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const fetchHospitalPolicies = async () => {
      if (!preliminaryInfo?.hospital) return;
      setLoading(true);

      try {
        // First, get the caseId from the first bill
        const firstBill = billAnalysis?.[0];
        if (!firstBill) {
          throw new Error("No bills available");
        }

        const caseResult = await client.graphql({
          query: getBillCase,
          variables: {
            billName: firstBill.billName,
          },
        });

        const caseId = caseResult.data.listBills.items[0]?.caseId;
        if (!caseId) {
          throw new Error("Could not find associated case");
        }

        // Get FPL data first
        const fplResponse = await client.graphql({
          query: getFederalPovertyLimitsByCriteria,
          variables: {
            year: 2024,
            state: getFPLState(preliminaryInfo.state),
            householdSize: parseInt(preliminaryInfo.familySize),
            income: Math.round(preliminaryInfo.annualIncome),
          },
        });

        // Get hospital policies
        const policyResponse = await client.graphql({
          query: getHospitalDetailsByName,
          variables: { hospitalName: preliminaryInfo.hospital, limit: 1000 },
        });

        const validPolicies = policyResponse.data?.listHospitalPolicies?.items
          ?.map((policy) => ({
            ...policy,
            thresholdBottom: Number(policy.thresholdBottom),
            thresholdTop: Number(policy.thresholdTop),
            percentage: Number(policy.percentage || 0),
          }))
          .sort((a, b) => a.thresholdBottom - b.thresholdBottom);

        const fplItems = fplResponse.data?.listFederalPovertyLimits?.items;
        if (fplItems?.[0]) {
          const baseIncome = Number(fplItems[0].income);
          const multiple = Number(
            ((preliminaryInfo.annualIncome / baseIncome) * 100).toFixed(2)
          );

          // Find matching policy band
          const matchingPolicy = validPolicies.find(
            (policy) =>
              multiple >= policy.thresholdBottom &&
              multiple <= policy.thresholdTop
          );

          const status = matchingPolicy ? "Qualified" : "Not Qualified";

          // Convert matching policies to the correct format
          const matchingPolicies = validPolicies
            .filter(
              (policy) =>
                multiple >= policy.thresholdBottom &&
                multiple <= policy.thresholdTop
            )
            .map((policy) => ({
              typeOfCare: policy.typeOfCare,
              thresholdBottom: policy.thresholdBottom,
              thresholdTop: policy.thresholdTop,
              percentageDiscounted: 1 - policy.percentage, // Convert to discount percentage
              description: policy.percentageDescription || "",
            }));

          // Calculate the maximum discount percentage
          const maxDiscount = matchingPolicy
            ? (1 - matchingPolicy.percentage) * 100
            : 0;

          console.log("Updating case with:", {
            caseId,
            fplPercentage: maxDiscount,
            status,
            matchingPolicies,
          });

          // Update the Case record
          await client.graphql({
            query: updateCase,
            variables: {
              input: {
                id: caseId,
                fplPercentage: maxDiscount,
                qualificationStatus: status,
              },
            },
          });

          setFplBaseIncome(baseIncome);
          setIncomeMultiple(multiple);
          setHospitalPolicies(validPolicies);
          setQualificationStatus(status);
        }

        // Get application requirements
        const appReqResponse = await client.graphql({
          query: getApplicationRequirementsByHospital,
          variables: { hospitalName: preliminaryInfo.hospital },
        });

        const appReq =
          appReqResponse.data?.listApplicationRequirements?.items[0];
        if (appReq) {
          setApplicationRequirements(appReq);
          if (appReq.s3Url) {
            setPdfUrl(
              `https://medbillssvelte42572c26c30e42629ee0aa7315fab796e878e-sveltedev.s3.us-west-2.amazonaws.com/${appReq.s3Url}`
            );
          }
        }
      } catch (err) {
        console.error("Error in fetchHospitalPolicies:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHospitalPolicies();
  }, [preliminaryInfo, billAnalysis]);
  return (
    <Flex align="center" justify="center">
      <Paper
        shadow="xs"
        radius="lg"
        withBorder
        p="xl"
        mx="lg"
        mt="sm"
        w="85%"
        h="auto"
      >
        <LoadingOverlay visible={loading} overlayBlur={2} />

        <Stack spacing="xl">
          <Title order={4}>Financial Assistance Eligibility Check</Title>

          {error && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="Error"
              color="red"
            >
              {error}
            </Alert>
          )}

          <ScrollArea>
            <Accordion
              variant="separated"
              multiple
              defaultValue={["fpl-explanation", "eligibility", "requirements"]}
            >
              <Accordion.Item value="fpl-explanation">
                <Accordion.Control
                  icon={
                    <ThemeIcon color="blue" variant="light">
                      <IconCurrencyDollar size={16} />
                    </ThemeIcon>
                  }
                >
                  Understanding Federal Poverty Level (FPL)
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack spacing="md">
                    <Text>
                      The Federal Poverty Level (FPL) is a measure used by the
                      U.S. Department of Health and Human Services to determine
                      eligibility for various financial assistance programs. For
                      your household size of {preliminaryInfo?.familySize} in{" "}
                      {preliminaryInfo?.state}, the base FPL is{" "}
                      {formatCurrency(fplBaseIncome)}.
                    </Text>
                    <Text size="sm" weight={500}>
                      Your Income Level:{" "}
                      <Badge size="lg" variant="light">
                        {incomeMultiple}% of Federal Poverty Level
                      </Badge>
                    </Text>
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item value="eligibility">
                <Accordion.Control
                  icon={
                    <ThemeIcon color="blue" variant="light">
                      <IconPercentage size={16} />
                    </ThemeIcon>
                  }
                >
                  Available Financial Assistance
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack spacing="md">
                    {hospitalPolicies
                      .filter(
                        (policy) =>
                          incomeMultiple >= policy.thresholdBottom &&
                          incomeMultiple <= policy.thresholdTop
                      )
                      .map((policy, index) => (
                        <Card
                          key={index}
                          withBorder
                          shadow="sm"
                          radius="md"
                          p="md"
                        >
                          <Group position="apart" mb="xs">
                            <Text weight={500}>{policy.typeOfCare}</Text>
                            <Badge
                              color={getEligibilityColor(
                                policy,
                                incomeMultiple
                              )}
                              variant="light"
                              size="lg"
                            >
                              {/* Fix percentage calculation */}
                              {`${policy.percentage * 100}% Assistance`}
                            </Badge>
                          </Group>
                          <Text size="sm" color="dimmed">
                            For patients with income between{" "}
                            {policy.thresholdBottom}% and {policy.thresholdTop}%
                            of FPL (
                            {formatCurrency(
                              (fplBaseIncome * policy.thresholdBottom) / 100
                            )}{" "}
                            -{" "}
                            {formatCurrency(
                              (fplBaseIncome * policy.thresholdTop) / 100
                            )}{" "}
                            annually)
                          </Text>
                          {policy.percentageDescription &&
                            policy.percentageDescription !== "nan" && (
                              <Text size="sm" mt="xs">
                                {policy.percentageDescription}
                              </Text>
                            )}
                          <Text size="sm" mt="md" weight={500}>
                            Your Responsibility: {policy.percentage * 100}% of
                            the bill
                          </Text>
                        </Card>
                      ))}
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>

              {applicationRequirements && (
                <Accordion.Item value="requirements">
                  <Accordion.Control
                    icon={
                      <ThemeIcon color="blue" variant="light">
                        <IconChecklist size={16} />
                      </ThemeIcon>
                    }
                  >
                    How to Apply
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack spacing="md">
                      <List
                        spacing="md"
                        size="sm"
                        icon={
                          <ThemeIcon color="blue" size={24} radius="xl">
                            <IconChecklist size={16} />
                          </ThemeIcon>
                        }
                      >
                        {formatRequirements(
                          applicationRequirements.requirement
                        ).map((req, index) => (
                          <List.Item key={index}>
                            <Text weight={500}>{req.title}</Text>
                            <Text size="sm" color="dimmed" mt={4}>
                              {req.description}
                            </Text>
                          </List.Item>
                        ))}
                      </List>

                      {pdfUrl && (
                        <Box
                          sx={{
                            width: "200px",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            leftIcon={<IconFileDownload size={16} />}
                            component="a"
                            href={pdfUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="light"
                          >
                            Download Application Form
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              )}
            </Accordion>
            <Accordion defaultValue="dispute-letter">
              <Accordion.Item value="dispute-letter">
                <Accordion.Control
                  icon={
                    <ThemeIcon color="blue" variant="light">
                      <IconFileDownload size={16} />
                    </ThemeIcon>
                  }
                >
                  Generate Dispute Letter
                </Accordion.Control>

                <Accordion.Panel>
                  <Stack spacing="md">
                    <Text>
                      Based on our analysis of your medical bills, we can
                      generate a professional dispute letter addressing the
                      identified billing errors. This letter can be sent to your
                      healthcare provider to request a review and correction of
                      these charges.
                    </Text>
                    <Box
                      sx={{
                        width: "200px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={generateDisputeLetter}
                        leftIcon={<IconFileDownload size={16} />}
                        loading={loading}
                      >
                        Generate Dispute Letter
                      </Button>
                    </Box>
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </ScrollArea>
        </Stack>
      </Paper>
    </Flex>
  );
};

export default Step4;
