import React, { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextInput,
  Checkbox,
  Flex,
  Box,
  Title,
  Stack,
  Container,
  Select,
  Group,
} from "@mantine/core";
import { Amplify } from "aws-amplify";
import { getCurrentUser } from "aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
import { useSignup, SignupProvider } from "./Signup_context";
import { createUserProfile } from "../graphql/mutations";

Amplify.configure(awsExports);
const client = generateClient();

function ProfileSetup({ signupData, updateSignupData }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUserId() {
      try {
        const { username, userId } = await getCurrentUser();
        setUserId(userId || username);
      } catch (error) {
        console.error("Error fetching user:", error);
        setErrorMessage(
          "Unable to fetch user information. Please ensure you're logged in."
        );
      }
    }
    fetchUserId();
  }, []);

  const handleNext = async () => {
    if (validateInputs() && userId) {
      try {
        const response = await client.graphql({
          query: createUserProfile,
          variables: {
            input: {
              userId: userId,
              hospital: signupData.hospital,
              insuranceType: signupData.insuranceType,
              insurer: signupData.insurer,
              age: parseInt(signupData.age, 10),
              first_name: signupData.firstName,
              last_name: signupData.lastName,
              billAmount: parseInt(signupData.billAmount),
              selectedServices: JSON.stringify(signupData.selectedServices),
              serviceDescriptions: JSON.stringify(
                signupData.serviceDescriptions
              ),
              inCollections: signupData.inCollections.toString(),
              s3ImageKey: "",
            },
          },
        });
        console.log("Full API Response:", response);

        const { data, errors } = response;

        if (errors) {
          console.error("Errors:", errors);
          console.log("Data:", data);
          setErrorMessage(JSON.stringify(errors));
        } else {
          // Profile created successfully, you can navigate to the next page or show a success message
          navigate("/profile-created");
        }
      } catch (error) {
        console.error("Error during profile creation:", error);
        setErrorMessage("Failed to complete profile setup. Please try again.");
      }
    } else {
      setErrorMessage(
        "Please fill in all required fields and accept the terms."
      );
    }
  };

  const validateInputs = () => {
    return (
      signupData.hospital.trim() !== "" &&
      signupData.insuranceType.trim() !== "" &&
      signupData.insurer.trim() !== "" &&
      signupData.termsAccepted
    );
  };

  return (
    <Flex align="center" justify="center" h={"100vh"}>
      <Box>
        <Stack>
          <Title> Complete your Profile</Title>
          <Group>
            <TextInput
              label="First Name"
              placeholder="First Name"
              required
              value={signupData.firstName}
              onChange={(e) => updateSignupData({ firstName: e.target.value })}
            />
            <TextInput
              label="Last Name"
              placeholder="Last Name"
              required
              value={signupData.lastName}
              onChange={(e) => updateSignupData({ lastName: e.target.value })}
            />
          </Group>

          <TextInput
            label="Age"
            placeholder="Age"
            required
            type="number"
            value={signupData.age}
            onChange={(e) => updateSignupData({ age: e.target.value })}
          />
          <TextInput
            label="Hospital"
            placeholder="Hospital"
            required
            value={signupData.hospital}
            onChange={(e) => updateSignupData({ hospital: e.target.value })}
          />
          <TextInput
            label="Insurance Type"
            placeholder="Insurance Type"
            required
            value={signupData.insuranceType}
            onChange={(e) =>
              updateSignupData({ insuranceType: e.target.value })
            }
          />
          <TextInput
            label="Insurer"
            placeholder="Insurer"
            required
            value={signupData.insurer}
            onChange={(e) => updateSignupData({ insurer: e.target.value })}
          />
          <TextInput
            label="Bill Amount"
            placeholder="Bill Amount"
            required
            type="number"
            value={signupData.billAmount}
            onChange={(e) => updateSignupData({ billAmount: e.target.value })}
          />
          <Checkbox
            size="xs"
            label="In Collections"
            checked={signupData.inCollections}
            color="blue.9"
            onChange={(e) =>
              updateSignupData({ inCollections: e.target.checked })
            }
          />
          <Checkbox
            size="xs"
            label="I accept the terms and conditions"
            color="blue.9"
            checked={signupData.termsAccepted}
            onChange={(e) =>
              updateSignupData({ termsAccepted: e.target.checked })
            }
          />
          {errorMessage && (
            <div style={{ color: "red", marginTop: 10 }}>{errorMessage}</div>
          )}
          <Button onClick={handleNext} color="blue.9">
            Complete Profile
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
}

function SignupStorage() {
  const { signupData, updateSignupData } = useSignup();

  return (
    <SignupProvider>
      <ProfileSetup
        signupData={signupData}
        updateSignupData={updateSignupData}
      />
    </SignupProvider>
  );
}

export default SignupStorage;
