// src/components/Header.js
import React, { useState } from "react";
import { Container, Group, Image, Burger } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import classes from "../styles/landing.module.css";
import logo from "../assets/CodaHx_logo_blue.svg";

const Header = () => {
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState("/about");

  const items = [
    { link: "/about", label: "Features" },
    { link: "/pricing", label: "Pricing" },
  ].map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={classes.linkH}
      data-active={active === link.link || undefined}
      onClick={(event) => {
        event.preventDefault();
        setActive(link.link);
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <header className={classes.header}>
      <Container size="xl" className={classes.innerH}>
        <Image src={logo} h={"3em"} />
        <Group gap="xl" visibleFrom="xs" mr="lg" pr="lg">
          {items}
        </Group>
        <Burger opened={opened} onClick={toggle} hiddenFrom="xs" size="sm" />
      </Container>
    </header>
  );
};

export default Header;
