import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextInput } from "@mantine/core";

function SignupVerification() {
  const navigate = useNavigate();
  const [description, setDescription] = useState("");

  const handleNext = () => {
    if (description.trim() !== "") {
      navigate("/Landing");
    } else {
      alert("Please enter the correct verification code.");
    }
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Verification Code</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <TextInput
          value={description}
          onChange={(event) => setDescription(event.currentTarget.value)}
          placeholder="Enter your service description"
          autosize
          minRows={3}
          styles={{
            input: { color: "green" },
            label: { color: "green" },
            root: { minWidth: "300px", width: "30%" },
          }}
        />
        <Button
          onClick={handleNext}
          style={{
            marginTop: "10px",
            backgroundColor: "green",
            color: "white",
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default SignupVerification;
