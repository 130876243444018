import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Box,
  Title,
  Stack,
  Container,
  Select,
} from "@mantine/core";
import { useSignup } from "./Signup_context"; // make sure this import is correct
//
function Signup_insurance() {
  const navigate = useNavigate();
  const { signupData, updateSignupData } = useSignup(); // Using context

  const handleInsuranceTypeChange = (value) => {
    updateSignupData({ ...signupData, insuranceType: value });
  };
  //
  const handleInsurerChange = (value) => {
    updateSignupData({ ...signupData, insurer: value });
  };

  const handleNext = () => {
    if (signupData.insuranceType && signupData.insurer) {
      console.log("Current insurance data:", signupData);
      navigate("/signup");
    } else {
      alert("Please select your insurance type and insurer.");
    }
  };

  return (
    <div>
      <Flex size={"sm"} align="center" justify="center" h={"100vh"}>
        <Box h="25em">
          <Stack>
            <Title> What kind of insurance do you have?</Title>
            <Select
              placeholder="Choose the type of insurance you have."
              searchable
              nothingFound="No options"
              data={[
                "Medicare",
                "Medicaid",
                "Exclusive Payer Organization (EPO)",
                "Point of Service (POS)",
                "Health Maintenance Organization (HMO)",
                "Preferred Provider Organization (PPO)",
                "I don’t have insurance",
              ]}
              value={signupData.insuranceType}
              onChange={(value) => handleInsuranceTypeChange(value)}
              styles={{
                root: { minWidth: "300px" },
              }}
            />
            <Title mt="lg"> Who is your insurer? </Title>
            <Select
              placeholder="Choose the insurance that you have."
              searchable
              nothingFound="No options"
              data={[
                "UnitedHealthcare",
                "Cigna",
                "Anthem",
                "Blue Cross Blue Shield",
                "Other",
              ]}
              value={signupData.insurer}
              onChange={(value) => handleInsurerChange(value)}
              styles={{
                root: { minWidth: "300px" },
              }}
            />
          </Stack>
          <Container align="center">
            <Button onClick={handleNext} color="blue.9" mt="lg">
              Next
            </Button>
          </Container>
        </Box>
      </Flex>
    </div>
  );
}

export default Signup_insurance;
