import React from "react";
import { Stack, Group, Button, Text, Title } from "@mantine/core";
import { IconZoomExclamation, IconZoomMoney } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();

  const goToNewBill = () => {
    navigate("/new-bill");
  };

  const goToFpl = () => {
    navigate("/parentfpl");
  };

  return (
    <>
      <Stack px="lg">
        <h2> Welcome to CodaHx</h2>
        <Group>
          <Button
            variant="gradient"
            gradient={{ from: "teal", to: "lime", deg: 63 }}
            size="xl"
            h={"120px"}
            radius="md"
            onClick={goToNewBill}
          >
            <Group justify="space-between">
              <IconZoomExclamation size={"30px"} />
              <Stack align="flex-start" gap="xs">
                <Text> Feature 1</Text>
                <Title order={3}> Bill Error Checking</Title>
              </Stack>
            </Group>
          </Button>

          <Button
            variant="gradient"
            gradient={{ from: "rgba(97, 213, 255, 1)", to: "indigo", deg: 63 }}
            size="xl"
            h={"120px"}
            radius="md"
            onClick={goToFpl}
          >
            <Group justify="space-between">
              <IconZoomMoney size={"30px"} />
              <Stack align="flex-start" gap="xs">
                <Text> Feature 2</Text>
                <Title order={3}>Financial Assistance</Title>
              </Stack>
            </Group>
          </Button>
        </Group>
      </Stack>
    </>
  );
}
