import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Step0 from "./new-report-feature/Step0";
import Step1 from "./new-report-feature/Step1";
import Step2 from "./new-report-feature/Step2";
import Step3 from "./new-report-feature/Step3";
import Step4 from "./new-report-feature/Step4";
import {
  AppShell,
  Stepper,
  Button,
  Group,
  Title,
  Box,
  Image,
  Container,
  Text,
} from "@mantine/core";
import { IconStethoscope } from "@tabler/icons-react";

export default function NewReport() {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [billAnalysis, setBillAnalysis] = useState(null);
  const [preliminaryInfo, setPreliminaryInfo] = useState(null);

  const nextStep = () => {
    if (active === 5) {
      navigate("../home");
    }
    setActive((current) => (current < 5 ? current + 1 : current));
  };

  const prevStep = () => {
    if (active === 0) {
      navigate("../home");
    }
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  // Validate if we can proceed to next step
  const canProceed = () => {
    switch (active) {
      case 0:
        return preliminaryInfo !== null;
      case 1:
        return billAnalysis !== null;
      default:
        return true;
    }
  };

  return (
    <AppShell
      header={{ height: 70 }}
      padding="md"
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          padding: 0,
          paddingTop: "70px",
        },
        header: {
          backgroundColor: theme.colors.blue[7],
          borderBottom: "none",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        },
      })}
    >
      <AppShell.Header>
        <Container size="xl" h="100%">
          <Group h="100%" px="md" position="apart">
            <Group spacing="xs">
              <IconStethoscope size={28} color="white" stroke={2} />
              <Title
                order={3}
                c="white"
                fw={600}
                style={{ letterSpacing: "-0.5px" }}
              >
                CodaHx - Medical Bill Analysis Tool
              </Title>
            </Group>
            <Group>
              {/* You could add additional header items here like user profile, etc. */}
            </Group>
          </Group>
        </Container>
      </AppShell.Header>

      <AppShell.Main>
        <Container size="xl" px="md" py="xl">
          <Stepper
            active={active}
            onStepClick={setActive}
            mt="xl"
            styles={(theme) => ({
              root: {
                padding: theme.spacing.lg,
                backgroundColor: "white",
                borderRadius: theme.radius.md,
                boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
              },
              separator: {
                backgroundColor: theme.colors.blue[2],
              },
              stepIcon: {
                borderColor: active
                  ? theme.colors.blue[7]
                  : theme.colors.gray[4],
              },
              stepCompletedIcon: {
                backgroundColor: "transparent",
              },
              stepBody: {
                margin: "0 .5rem",
              },
            })}
          >
            <Stepper.Step label="Preliminary Info" description="Step 1">
              <Box mt="xl">
                <Step0
                  setPreliminaryInfo={setPreliminaryInfo}
                  nextStep={nextStep}
                />
              </Box>
            </Stepper.Step>
            <Stepper.Step
              label="Upload Documents"
              description="Step 2"
              disabled={!preliminaryInfo}
            >
              <Box mt="xl">
                {preliminaryInfo ? (
                  <Step1
                    preliminaryInfoId={preliminaryInfo.id}
                    setBillAnalysis={setBillAnalysis}
                    nextStep={nextStep}
                  />
                ) : (
                  <Text c="dimmed" ta="center">
                    Please complete the preliminary information first.
                  </Text>
                )}
              </Box>
            </Stepper.Step>
            <Stepper.Step
              label="View Explanation"
              description="Step 3"
              disabled={!billAnalysis}
            >
              <Box mt="xl">
                <Step2
                  billAnalysis={billAnalysis}
                  preliminaryInfo={preliminaryInfo}
                />
              </Box>
            </Stepper.Step>
            <Stepper.Step label="Check for Errors" description="Step 4">
              <Box mt="xl">
                <Step3
                  billAnalysis={billAnalysis}
                  preliminaryInfo={preliminaryInfo}
                />
              </Box>
            </Stepper.Step>
            <Stepper.Step label="Next Steps" description="Step 5">
              <Box mt="xl">
                <Step4
                  billAnalysis={billAnalysis}
                  preliminaryInfo={preliminaryInfo}
                />
              </Box>
            </Stepper.Step>
          </Stepper>

          <Group position="center" mt="xl" mb="xl">
            <Button
              variant="default"
              onClick={prevStep}
              size="md"
              sx={(theme) => ({
                "&:hover": {
                  backgroundColor: theme.colors.gray[1],
                },
              })}
            >
              Back
            </Button>
            <Button
              onClick={nextStep}
              size="md"
              disabled={!canProceed()}
              sx={(theme) => ({
                backgroundColor: theme.colors.blue[7],
                "&:hover": {
                  backgroundColor: theme.colors.blue[8],
                },
              })}
            >
              Next step
            </Button>
          </Group>
        </Container>
      </AppShell.Main>
    </AppShell>
  );
}
