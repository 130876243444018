import React from "react";
import { useNavigate } from "react-router-dom";
import { AppShell, Burger, Group, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Button } from "@mantine/core";
import {
  IconAlignBoxBottomRight,
  IconReceipt2,
  IconFileSmile,
  IconUserCircle,
  IconSettings,
} from "@tabler/icons-react";
import Dashboard from "../components/Dashboard";

export default function Home({ signOut }) {
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();

  const goToPage = (page) => {
    console.log("to bills");
    navigate(`../${page}`);
  };

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 250, breakpoint: "sm", collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header>
        <Group h="100%" px="sm" justify="space-between">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Button variant="transparent" color="rgba(0, 0, 0, 1)" size="md">
            CodaHx
          </Button>
          <Button variant="transparent" color="red" onClick={signOut}>
            Sign Out
          </Button>
        </Group>
      </AppShell.Header>

      {/* Sidebar */}
      <AppShell.Navbar px="xs" pt="lg">
        <Stack align="flex-start" justify="flex-start">
          <Button
            variant="subtle"
            color="rgba(0, 0, 0, 1)"
            size="md"
            justify="flex-start"
            fullWidth
            leftSection={<IconAlignBoxBottomRight size={14} />}
            onClick={() => goToPage("home")}
          >
            Dashboard
          </Button>

          <Button
            variant="subtle"
            color="rgba(0, 0, 0, 1)"
            size="md"
            justify="flex-start"
            fullWidth
            leftSection={<IconReceipt2 size={14} />}
            onClick={() => goToPage("bills")}
          >
            Bills
          </Button>

          <Button
            variant="subtle"
            color="rgba(0, 0, 0, 1)"
            size="md"
            justify="flex-start"
            fullWidth
            leftSection={<IconFileSmile size={14} />}
            onClick={() => goToPage("applications")}
          >
            Applications
          </Button>

          <Button
            variant="subtle"
            color="rgba(0, 0, 0, 1)"
            size="md"
            justify="flex-start"
            fullWidth
            leftSection={<IconUserCircle size={14} />}
            onClick={() => goToPage("profile")}
          >
            Profile
          </Button>

          <Button
            variant="subtle"
            color="rgba(0, 0, 0, 1)"
            size="md"
            justify="flex-start"
            fullWidth
            leftSection={<IconSettings size={14} />}
            onClick={() => goToPage("settings")}
          >
            Settings
          </Button>
        </Stack>
      </AppShell.Navbar>

      {/* Main Section */}
      <AppShell.Main bg="gray.0">
        <Dashboard />
      </AppShell.Main>
    </AppShell>
  );
}
