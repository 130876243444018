import React, { createContext, useContext, useState } from "react";

const SignupContext = createContext();

export const useSignup = () => useContext(SignupContext);

export const SignupProvider = ({ children }) => {
  const [signupData, setSignupData] = useState({
    hospital: "Default Hospital",
    insuranceType: "Default Insurance Type",
    insurer: "Default Insurance",
    billAmount: 2,
    selectedServices: [],
    serviceDescriptions: {},
    email: "",
    inCollections: "No",
    password: "",
    username: "",
  });

  const updateSignupData = (data) => {
    setSignupData((prev) => ({ ...prev, ...data }));
  };

  return (
    <SignupContext.Provider value={{ signupData, updateSignupData }}>
      {children}
    </SignupContext.Provider>
  );
};
