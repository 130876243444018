import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  NumberInput,
  Checkbox,
  Flex,
  Box,
  Title,
  Stack,
  Text,
  Container,
} from "@mantine/core";
import { useSignup } from "./Signup_context"; // Make sure this is correctly imported

function Signup_payment() {
  const navigate = useNavigate();
  const { signupData, updateSignupData } = useSignup();

  const handleNext = () => {
    console.log("Payment data before navigating:", signupData);
    navigate("/signup_insurance"); // Assuming you navigate back to signup for demonstration
  };

  const handleNumberChange = (value) => {
    updateSignupData({ ...signupData, billAmount: value });
  };

  const handleCheckboxChange = (value) => {
    updateSignupData({ ...signupData, inCollections: value });
  };

  return (
    <div>
      <Flex size={"sm"} align="center" justify="center" h={"100vh"}>
        <Box h="25em">
          <Stack>
            <Title> How much is your bill?</Title>
            <NumberInput
              prefix="$"
              value={signupData.billAmount || 0}
              onChange={handleNumberChange}
              placeholder="Enter a number"
              min={0}
              max={100}
              step={1}
              styles={{
                root: { minWidth: "200px" },
              }}
            />

            <Text> Is it in collections?</Text>
            <Checkbox
              label="Yes"
              checked={signupData.inCollections === "Yes"}
              onChange={() => handleCheckboxChange("Yes")}
              color="blue.9"
            />
            <Checkbox
              label="No"
              checked={signupData.inCollections === "No"}
              onChange={() => handleCheckboxChange("No")}
              color="blue.9"
            />
          </Stack>
          <Container align="center">
            <Button onClick={handleNext} color="blue.9" mt="sm">
              Next
            </Button>
          </Container>
        </Box>
      </Flex>
    </div>
  );
}

export default Signup_payment;
