import React, { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { generateClient } from "@aws-amplify/api";
import {
  TextInput,
  NumberInput,
  RadioGroup,
  Radio,
  Text,
  Paper,
  Title,
  Flex,
  ScrollArea,
  Select,
  Button,
  Group,
  LoadingOverlay,
} from "@mantine/core";
import awsconfig from "../../aws-exports";
import * as mutations from "../../graphql/mutations";

Amplify.configure(awsconfig);

function Step0({ setPreliminaryInfo, nextStep }) {
  const [name, setName] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [typeOfCare, setTypeOfCare] = useState("");
  const [familySize, setFamilySize] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");
  const [hospitals, setHospitals] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hospitalMapping, setHospitalMapping] = useState({});
  const [selectedInsurance, setSelectedInsurance] = useState("");

  useEffect(() => {
    // Fetch hospitals
    fetch("/data/hospital_name_mapping.json")
      .then((response) => response.json())
      .then((data) => {
        setHospitalMapping(data); // Store the entire mapping
        const hospitalOptions = Object.keys(data).map((key) => ({
          value: key,
          label: key,
        }));
        setHospitals(hospitalOptions);
      })
      .catch((error) => console.error("Error loading hospital data:", error));

    // Fetch states
    fetch("/data/locations.json")
      .then((response) => response.json())
      .then((data) => {
        const stateOptions = data.states.map((state) => ({
          value: state.name,
          label: state.name,
        }));
        setStates(stateOptions);
      })
      .catch((error) => console.error("Error loading location data:", error));
  }, []);

  useEffect(() => {
    if (selectedState) {
      fetch("/data/locations.json")
        .then((response) => response.json())
        .then((data) => {
          const stateData = data.states.find(
            (state) => state.name === selectedState
          );
          if (stateData) {
            const cityOptions = stateData.cities.map((city) => ({
              value: city,
              label: city,
            }));
            setCities(cityOptions);
          }
        })
        .catch((error) => console.error("Error loading city data:", error));
    } else {
      setCities([]);
    }
    setSelectedCity("");
  }, [selectedState]);

  const yearOptions = Array.from(
    { length: 101 },
    (_, i) => new Date().getFullYear() - i
  ).map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));

  const insuranceOptions = [
    { value: "medicare", label: "Medicare" },
    { value: "medicaid", label: "Medicaid" },
    { value: "no insurance", label: "No insurance" },
    { value: "cigna", label: "Cigna" },
    { value: "aetna", label: "Aetna" },
    { value: "united_healthcare", label: "United Healthcare" },
    { value: "humana", label: "Humana" },
    { value: "anthem", label: "Anthem" },
    { value: "kaiser", label: "Kaiser" },
    { value: "other", label: "Other" },
  ];
  const monthOptions = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const dayOptions = Array.from({ length: 31 }, (_, i) => i + 1).map((day) => ({
    value: day.toString().padStart(2, "0"),
    label: day.toString(),
  }));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Validate required fields
      if (
        !name ||
        !year ||
        !month ||
        !day ||
        !selectedHospital ||
        !typeOfCare ||
        !selectedState ||
        !selectedCity ||
        !familySize ||
        !annualIncome ||
        !selectedInsurance
      ) {
        throw new Error("Please fill in all required fields");
      }

      const { credentials } = await fetchAuthSession();
      if (!credentials) {
        throw new Error("User is not authenticated");
      }

      // Construct database info object (without hospitalId)
      // const databaseInfo = {
      //   name,
      //   dateOfBirth: `${year}-${month}-${day}`,
      //   hospital: selectedHospital,
      //   typeOfCare,
      //   state: selectedState,
      //   city: selectedCity,
      //   familySize: parseInt(familySize),
      //   annualIncome: parseFloat(annualIncome),
      // };
      // In the handleSubmit function of Step0, modify the databaseInfo object:
      const { userId } = await getCurrentUser();
      const databaseInfo = {
        userId: userId, // Add userId
        name,
        dateOfBirth: `${year}-${month}-${day}`,
        hospital: selectedHospital,
        typeOfCare,
        state: selectedState,
        city: selectedCity,
        familySize: parseInt(familySize),
        annualIncome: parseFloat(annualIncome),
        insurance: selectedInsurance, // Add insurance
      };

      const client = generateClient();
      const createPreliminaryInfoMutation = Object.values(mutations).find(
        (mutation) => mutation.includes("createPreliminaryInfo")
      );

      if (!createPreliminaryInfoMutation) {
        throw new Error("createPreliminaryInfo mutation not found");
      }

      // Save to database
      const result = await client.graphql({
        query: createPreliminaryInfoMutation,
        variables: { input: databaseInfo },
      });

      console.log(
        "Preliminary info saved successfully:",
        result.data.createPreliminaryInfo
      );

      // Create state object with both database info and hospitalId
      const stateInfo = {
        ...databaseInfo,
        id: result.data.createPreliminaryInfo.id,
        hospitalId: hospitalMapping[selectedHospital] || "N/A",
        insurance: selectedInsurance,
      };

      // Update parent component state with the complete info
      setPreliminaryInfo(stateInfo);
      console.log("Preliminary info saved:", stateInfo);
      // Clear form fields
      setName("");
      setYear("");
      setMonth("");
      setDay("");
      setSelectedHospital("");
      setTypeOfCare("");
      setSelectedState("");
      setSelectedCity("");
      setFamilySize("");
      setAnnualIncome("");
      setSelectedInsurance("");

      // Move to next step
      nextStep();
    } catch (error) {
      console.error("Error saving preliminary info:", error);
      setError(
        error.message ||
          "An error occurred while saving the information. Please try again."
      );
      if (error.errors) {
        error.errors.forEach((e) => console.error("GraphQL error:", e.message));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex align="center" justify="center">
        <Paper
          shadow="xs"
          radius="lg"
          withBorder
          p="xl"
          mx="lg"
          mt="sm"
          w="85%"
          h="auto"
        >
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <Title order={4}>Preliminary Information</Title>
          <Text color="dimmed">Please input the preliminary information.</Text>
          <ScrollArea h={"auto"} scrollbarSize={10} scrollHideDelay={0} pb="lg">
            <TextInput
              label="Name"
              placeholder="Enter your name"
              value={name}
              onChange={(event) => setName(event.currentTarget.value)}
              required
            />
            <Group grow>
              <Select
                label="Year"
                placeholder="Select year"
                value={year}
                onChange={setYear}
                data={yearOptions}
                required
                searchable
              />
              <Select
                label="Month"
                placeholder="Select month"
                value={month}
                onChange={setMonth}
                data={monthOptions}
                required
                searchable
              />
              <Select
                label="Day"
                placeholder="Select day"
                value={day}
                onChange={setDay}
                data={dayOptions}
                required
                searchable
              />
            </Group>
            <Group grow align="flex-start">
              <Select
                label="Name of hospital where you received care"
                placeholder="Select hospital"
                value={selectedHospital}
                onChange={setSelectedHospital}
                data={hospitals}
                required
                searchable
              />
              <Select
                label="Insurance Provider"
                placeholder="Select insurance provider"
                value={selectedInsurance}
                onChange={setSelectedInsurance}
                data={insuranceOptions}
                required
                searchable
              />
            </Group>
            <RadioGroup
              label="Type of care"
              value={typeOfCare}
              onChange={setTypeOfCare}
              required
            >
              <Radio
                value="inpatient"
                label="Inpatient - I stayed in the hospital"
              />
              <Radio
                value="outpatient"
                label="Outpatient - I did not stay in the hospital"
              />
            </RadioGroup>
            <Select
              label="State of residence"
              placeholder="Select your state"
              value={selectedState}
              onChange={setSelectedState}
              data={states}
              required
              searchable
            />
            <Select
              label="City of residence"
              placeholder="Select your city"
              value={selectedCity}
              onChange={setSelectedCity}
              data={cities}
              disabled={!selectedState}
              required
              searchable
            />
            <NumberInput
              label="Family size"
              placeholder="Enter number of family members"
              value={familySize}
              onChange={setFamilySize}
              min={1}
              max={10}
              required
            />
            <NumberInput
              label="Approx. annual family income"
              placeholder="Enter your family's annual income"
              value={annualIncome}
              onChange={setAnnualIncome}
              required
              prefix="$"
            />
            {error && (
              <Text color="red" size="sm" mt="sm">
                {error}
              </Text>
            )}
            <Button type="submit" mt="md" disabled={loading}>
              Submit
            </Button>
          </ScrollArea>
        </Paper>
      </Flex>
    </form>
  );
}

export default Step0;
