import React, { useState, useMemo } from "react";
import {
  Text,
  Paper,
  Table,
  AppShell,
  Title,
  ScrollArea,
  UnstyledButton,
  Stack,
  Group,
  ThemeIcon,
  rem,
  Flex,
  Anchor,
  Grid,
  Badge,
  Alert,
  Box,
} from "@mantine/core";
import {
  IconFileInvoice,
  IconChevronLeft,
  IconChevronRight,
  IconBuildingHospital,
  IconInfoCircle,
} from "@tabler/icons-react";

function BillSummary({ billAnalysis, preliminaryInfo }) {
  const [selectedBillIndex, setSelectedBillIndex] = useState(null);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);

  const toggleNavbar = () => setNavbarCollapsed(!navbarCollapsed);
  console.log(preliminaryInfo);

  const bills = useMemo(
    () =>
      billAnalysis
        ? billAnalysis.map((result) => {
            const totalCharge = result.result.reduce(
              (sum, item) => sum + (item.Charge || 0),
              0
            );
            return {
              billName: result.billName,
              totalCharge,
              details: result.result,
            };
          })
        : [],
    [billAnalysis]
  );

  const formatCurrency = (amount) => `$${amount.toFixed(2)}`;
  const currentDate = new Date().toLocaleDateString();

  const BillButton = ({ billName, isActive, onClick }) => (
    <UnstyledButton
      onClick={onClick}
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color:
          theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
        backgroundColor: isActive
          ? theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[1]
          : "transparent",
        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[1],
        },
      })}
    >
      <Group>
        <ThemeIcon color="blue" variant="light">
          <IconFileInvoice style={{ width: rem(16), height: rem(16) }} />
        </ThemeIcon>
        <Text size="sm">{billName}</Text>
      </Group>
    </UnstyledButton>
  );

  const allBillsTable = () => (
    <Stack spacing="md">
      <Alert color="blue" radius="md">
        <Group>
          <ThemeIcon color="blue" size="lg" variant="light">
            <IconInfoCircle size={20} />
          </ThemeIcon>
          <Text fw={700} c="blue.7">
            Below is a Summary of the charges on your bill. Click on the sidebar
            to view each bills details.
          </Text>
        </Group>
      </Alert>

      <Table
        striped
        highlightOnHover
        withBorder
        withColumnBorders
        styles={(theme) => ({
          root: {
            border: `1px solid ${theme.colors.blue[6]}`,
            borderRadius: theme.radius.sm,
          },
          th: {
            backgroundColor: theme.colors.blue[0],
            color: theme.colors.blue[9],
            textAlign: "center",
          },
          td: {
            textAlign: "center",
          },
        })}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Bill Name</Table.Th>
            <Table.Th>Date</Table.Th>
            <Table.Th>Total Charged</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {bills.map((bill, index) => (
            <Table.Tr key={index}>
              <Table.Td>
                {/* Make the bill name clickable */}
                <Anchor
                  component="button"
                  size="sm"
                  color="blue"
                  onClick={() => setSelectedBillIndex(index)}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  {bill.billName}
                </Anchor>
              </Table.Td>
              <Table.Td>{currentDate}</Table.Td>
              <Table.Td>{formatCurrency(bill.totalCharge)}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
  );

  const individualBillTable = () => {
    const bill = bills[selectedBillIndex];
    return (
      <Table
        striped
        highlightOnHover
        withBorder
        withColumnBorders
        styles={(theme) => ({
          root: {
            border: `1px solid ${theme.colors.blue[6]}`,
            borderRadius: theme.radius.sm,
          },
          th: {
            backgroundColor: theme.colors.blue[0],
            color: theme.colors.blue[9],
            textAlign: "center",
          },
          td: {
            textAlign: "center",
          },
        })}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>CPT Code</Table.Th>
            <Table.Th>Procedure</Table.Th>
            <Table.Th>Explanation</Table.Th>
            <Table.Th>Charged Price</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {bill.details.map((item, index) => (
            <Table.Tr key={index}>
              <Table.Td>{item["CPT Code"]}</Table.Td>
              <Table.Td>{item["Procedure"]}</Table.Td>
              <Table.Td>{item["Layman Explanation"]}</Table.Td>
              <Table.Td>{formatCurrency(item["Charge"])}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    );
  };

  if (bills.length === 0) {
    return <Text>No analysis results available.</Text>;
  }

  return (
    <Box mt="xl">
      <Grid gutter="md">
        {/* Left Sidebar */}
        <Grid.Col
          span={navbarCollapsed ? 1 : 3}
          style={{ maxWidth: navbarCollapsed ? 80 : 300 }}
        >
          <Paper shadow="sm" radius="md" p="md" style={{ height: "100%" }}>
            <Flex direction="column" h="100%">
              <Group position="apart" mb="md">
                {!navbarCollapsed && (
                  <Text fw={500} size="sm" color="dimmed">
                    BILLS LIST
                  </Text>
                )}
                <UnstyledButton onClick={toggleNavbar}>
                  <ThemeIcon color="blue" variant="light" size="sm">
                    {navbarCollapsed ? (
                      <IconChevronRight size={rem(16)} />
                    ) : (
                      <IconChevronLeft size={rem(16)} />
                    )}
                  </ThemeIcon>
                </UnstyledButton>
              </Group>
              <ScrollArea style={{ flex: 1 }}>
                {!navbarCollapsed && (
                  <Stack spacing="xs">
                    <Paper
                      p="xs"
                      withBorder
                      bg={selectedBillIndex === null ? "blue.0" : "transparent"}
                      sx={(theme) => ({
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: theme.colors.gray[0],
                        },
                      })}
                      onClick={() => setSelectedBillIndex(null)}
                    >
                      <Group>
                        <ThemeIcon color="blue" variant="light">
                          <IconFileInvoice
                            style={{ width: rem(16), height: rem(16) }}
                          />
                        </ThemeIcon>
                        <Box>
                          <Text size="sm" fw={500}>
                            All Bills
                          </Text>
                          <Text size="xs" color="dimmed">
                            Total:{" "}
                            {formatCurrency(
                              bills.reduce(
                                (sum, bill) => sum + bill.totalCharge,
                                0
                              )
                            )}
                          </Text>
                        </Box>
                      </Group>
                    </Paper>
                    {bills.map((bill, index) => (
                      <Paper
                        key={index}
                        p="xs"
                        withBorder
                        bg={
                          index === selectedBillIndex ? "blue.0" : "transparent"
                        }
                        sx={(theme) => ({
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: theme.colors.gray[0],
                          },
                        })}
                        onClick={() => setSelectedBillIndex(index)}
                      >
                        <Group>
                          <ThemeIcon color="blue" variant="light">
                            <IconFileInvoice
                              style={{ width: rem(16), height: rem(16) }}
                            />
                          </ThemeIcon>
                          <Box>
                            <Text size="sm" fw={500}>
                              {bill.billName}
                            </Text>
                            <Text size="xs" color="dimmed">
                              {formatCurrency(bill.totalCharge)}
                            </Text>
                          </Box>
                        </Group>
                      </Paper>
                    ))}
                  </Stack>
                )}
              </ScrollArea>
            </Flex>
          </Paper>
        </Grid.Col>

        {/* Main Content */}
        <Grid.Col span={navbarCollapsed ? 11 : 9}>
          <Stack spacing="md">
            {/* Summary Card */}
            <Paper shadow="sm" radius="md" p="md" withBorder>
              <Group position="apart" mb="xs">
                <Group spacing="xs">
                  <IconBuildingHospital size={24} stroke={1.5} color="blue" />
                  <Title order={4}>
                    {preliminaryInfo
                      ? preliminaryInfo.hospital
                      : "Not Selected"}
                  </Title>
                </Group>
                <Badge size="lg" variant="outline">
                  {currentDate}
                </Badge>
              </Group>
              <Group position="apart">
                <Text size="lg" fw={500} color="dimmed">
                  {selectedBillIndex === null
                    ? "All Bills Summary"
                    : `${bills[selectedBillIndex].billName}`}
                </Text>
                <Text size="xl" fw={700} color="blue">
                  Total:{" "}
                  {formatCurrency(
                    selectedBillIndex === null
                      ? bills.reduce((sum, bill) => sum + bill.totalCharge, 0)
                      : bills[selectedBillIndex].totalCharge
                  )}
                </Text>
              </Group>
            </Paper>

            {/* Bills Table */}
            <Paper shadow="sm" radius="md" withBorder>
              {selectedBillIndex === null
                ? allBillsTable()
                : individualBillTable()}
            </Paper>
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default BillSummary;
