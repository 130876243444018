import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateClient } from "@aws-amplify/api";
import { getCurrentUser } from "aws-amplify/auth";
import {
  Paper,
  Title,
  Tabs,
  Button,
  Group,
  Container,
  Loader,
  Table,
  Text,
  Badge,
  Box,
  Modal,
  Stack,
  Tooltip,
  Skeleton,
  Transition,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  listBills,
  listBillErrors,
  listPreliminaryInfos,
  listCases,
} from "../graphql/queries";
import { IconDownload } from "@tabler/icons-react";
import { getUrl } from "aws-amplify/storage";
import { Notification } from "@mantine/core";

function UserDashboard() {
  const navigate = useNavigate();
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("active");
  const [selectedBill, setSelectedBill] = useState(null);
  const apiClient = generateClient();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
    color: "",
  });

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const { userId } = await getCurrentUser();

      const [billsData, errorsData, prelimInfoData, casesData] =
        await Promise.all([
          apiClient.graphql({
            query: listBills,
            variables: {
              filter: { userId: { eq: userId } },
              limit: 1000,
            },
          }),
          apiClient.graphql({
            query: listBillErrors,
            variables: {
              filter: { userId: { eq: userId } },
              limit: 1000,
            },
          }),
          apiClient.graphql({
            query: listPreliminaryInfos,
            variables: {
              filter: { userId: { eq: userId } },
              limit: 1000,
            },
          }),
          apiClient.graphql({
            query: listCases,
            variables: {
              filter: { userId: { eq: userId } },
              limit: 1000,
            },
          }),
        ]);

      console.log("Raw Cases Data:", casesData);

      const bills = billsData.data.listBills.items;
      const errors = errorsData.data.listBillErrors.items;
      const prelimInfo = prelimInfoData.data.listPreliminaryInfos.items;
      const cases = casesData.data.listCases.items;

      const billsWithDetails = bills.map((bill) => {
        const relatedCase = cases.find((c) => c.id === bill.caseId);
        const relatedPrelimInfo = prelimInfo.find(
          (info) => info.id === bill.preliminaryInfoId
        );
        const relatedErrors = errors.filter(
          (error) => error.billName === bill.billName
        );
        const actualErrors = relatedErrors.filter(
          (error) => error.error !== "N/A"
        );

        // Use fplPercentage directly
        const assistancePercentage = relatedCase?.fplPercentage || 0;

        return {
          id: bill.id,
          caseId: bill.caseId,
          name: bill.billName,
          date: new Date(bill.createdAt).toLocaleDateString(),
          provider: relatedPrelimInfo?.hospital || "Not specified",
          insurance: relatedPrelimInfo?.insurance || "Not specified",
          city: relatedPrelimInfo?.city || "Not specified",
          state: relatedPrelimInfo?.state || "Not specified",
          familySize: relatedPrelimInfo?.familySize || "Not specified",
          annualIncome: relatedPrelimInfo?.annualIncome
            ? `$${relatedPrelimInfo.annualIncome.toLocaleString()}`
            : "Not specified",
          errors: actualErrors,
          preliminaryInfo: relatedPrelimInfo,
          assistancePercentage,
          disputeLetterUrl: relatedCase?.disputeLetterUrl,
          disputeLetterDate: relatedCase?.disputeLetterDate,
          qualificationStatus: relatedCase?.qualificationStatus,
          caseStatus: relatedCase?.status || "IN_PROGRESS",
        };
      });
      const sortedBills = billsWithDetails.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      console.log("Processed Bills:", sortedBills);
      setBills(sortedBills);
    } catch (err) {
      console.error("Error fetching user data:", err);
    } finally {
      setLoading(false);
    }
  };
  const getFilteredBills = () => {
    switch (activeTab) {
      case "active":
        return bills.filter((bill) => bill.errors.length > 0);
      case "pending":
        return bills.filter((bill) => bill.errors.length === 0);
      case "all":
        return bills;
      default:
        return bills;
    }
  };

  // Modify the handleDownloadLetter function:
  const handleDownloadLetter = async (disputeLetterUrl) => {
    try {
      if (!disputeLetterUrl) {
        setNotificationContent({
          title: "Error",
          message: "No dispute letter available",
          color: "red",
        });
        setShowNotification(true);
        // Auto hide after 3 seconds
        setTimeout(() => setShowNotification(false), 3000);
        return;
      }

      const { url } = await getUrl({
        key: disputeLetterUrl,
        options: {
          expiresIn: 3600,
        },
      });

      window.open(url, "_blank");

      setNotificationContent({
        title: "Success",
        message: "Opening dispute letter in new tab",
        color: "green",
      });
      setShowNotification(true);
      // Auto hide after 3 seconds
      setTimeout(() => setShowNotification(false), 3000);
    } catch (error) {
      console.error("Error downloading letter:", error);
      setNotificationContent({
        title: "Error",
        message: "Failed to download letter",
        color: "red",
      });
      setShowNotification(true);
      // Auto hide after 3 seconds
      setTimeout(() => setShowNotification(false), 3000);
    }
  };
  const filteredBills = getFilteredBills();

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      {showNotification && (
        <Notification
          title={notificationContent.title}
          color={notificationContent.color}
          onClose={() => setShowNotification(false)}
          withBorder
          radius="md"
          style={{
            position: "fixed",
            top: 20,
            right: 20,
            zIndex: 9999,
            minWidth: "300px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
        >
          {notificationContent.message}
        </Notification>
      )}
      <Header />
      <Container size="xl" py="xl" style={{ flex: 1, marginTop: "80px" }}>
        <Paper shadow="sm" radius="md" p="xl" withBorder>
          <Group justify="space-between" mb="lg">
            <Title order={1}>Your Cases</Title>
            <Button
              leftSection={<IconPlus size={14} />}
              color="teal"
              onClick={() => navigate("/new-report")}
            >
              Start A New Case
            </Button>
          </Group>

          <Tabs
            value={activeTab}
            onChange={setActiveTab}
            mb="md"
            variant="pills"
            radius="md"
          >
            <Tabs.List>
              <Tabs.Tab value="active">
                Active Cases (
                {bills.filter((bill) => bill.errors.length > 0).length})
              </Tabs.Tab>
              <Tabs.Tab value="pending">
                Pending Cases (
                {bills.filter((bill) => bill.errors.length === 0).length})
              </Tabs.Tab>
              <Tabs.Tab value="all">All Cases ({bills.length})</Tabs.Tab>
            </Tabs.List>
          </Tabs>

          {loading ? (
            <Box ta="center" py="xl">
              <Loader size="lg" type="bars" />
            </Box>
          ) : (
            <Table striped highlightOnHover withTableBorder>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Bill Name</Table.Th> {/* Added this line */}
                  <Table.Th>Date</Table.Th>
                  <Table.Th>Provider</Table.Th>
                  <Table.Th>Insurance</Table.Th>
                  <Table.Th>Location</Table.Th>
                  <Table.Th>Household</Table.Th>
                  <Table.Th>Income</Table.Th>
                  <Table.Th>Errors Found</Table.Th>
                  <Table.Th>Financial Assistance</Table.Th>
                  <Table.Th>Actions</Table.Th>
                  <Table.Th>Status</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {filteredBills.length === 0 ? (
                  <Table.Tr>
                    <Table.Td colSpan={11}>
                      <Text c="dimmed" ta="center" py="md">
                        No cases found. Start by creating a new case.
                      </Text>
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  filteredBills.map((bill, index) => (
                    <Table.Tr
                      key={bill.id}
                      style={{
                        cursor: "pointer",
                        transition: "background-color 0.2s",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#f8f9fa")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      <Table.Td>{bill.name}</Table.Td> {/* Added this line */}
                      <Table.Td>{bill.date}</Table.Td>
                      <Table.Td>{bill.provider}</Table.Td>
                      <Table.Td>{bill.insurance}</Table.Td>
                      <Table.Td>{`${bill.city}, ${bill.state}`}</Table.Td>
                      <Table.Td>{bill.familySize}</Table.Td>
                      <Table.Td>{bill.annualIncome}</Table.Td>
                      <Table.Td>{bill.errors.length}</Table.Td>
                      <Table.Td>
                        {bill.assistancePercentage > 0 ? (
                          <Badge color="green" variant="light">
                            {bill.assistancePercentage}% Discount
                          </Badge>
                        ) : (
                          <Badge color="gray" variant="light">
                            Not Available
                          </Badge>
                        )}
                      </Table.Td>
                      <Table.Td onClick={(e) => e.stopPropagation()}>
                        {bill.disputeLetterUrl ? (
                          <Tooltip label="Download dispute letter" withArrow>
                            <Button
                              variant="subtle"
                              size="xs"
                              leftSection={<IconDownload size={14} />}
                            >
                              Letter
                            </Button>
                          </Tooltip>
                        ) : (
                          <Badge color="gray" variant="dot">
                            No Letter
                          </Badge>
                        )}
                      </Table.Td>
                      <Table.Td>
                        <Badge
                          color={bill.errors.length > 0 ? "red" : "green"}
                          variant="light"
                        >
                          {bill.errors.length > 0
                            ? "Issues Found"
                            : "No Issues"}
                        </Badge>
                      </Table.Td>
                    </Table.Tr>
                  ))
                )}
              </Table.Tbody>
            </Table>
          )}
        </Paper>

        <Modal
          opened={selectedBill !== null}
          onClose={() => setSelectedBill(null)}
          title={selectedBill?.name || "Bill Details"}
          size="xl"
        >
          {selectedBill?.errors?.length === 0 ? (
            <Text c="dimmed" ta="center" py="xl">
              No errors found in this bill.
            </Text>
          ) : (
            <Table striped highlightOnHover withTableBorder>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>CPT Code</Table.Th>
                  <Table.Th>Procedure</Table.Th>
                  <Table.Th>Error</Table.Th>
                  <Table.Th>Error Type</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {selectedBill?.errors?.map((error, index) => (
                  <Table.Tr key={index}>
                    <Table.Td>{error.cptCode}</Table.Td>
                    <Table.Td>{error.procedure}</Table.Td>
                    <Table.Td>{error.error}</Table.Td>
                    <Table.Td>{error.errorType}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          )}
        </Modal>
      </Container>
      <Footer />
    </div>
  );
}

export default UserDashboard;
