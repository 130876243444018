import React, { useState, useEffect, useMemo } from "react";
import {
  Text,
  Paper,
  Table,
  AppShell,
  Title,
  ScrollArea,
  UnstyledButton,
  Stack,
  Group,
  ThemeIcon,
  rem,
  Flex,
  Anchor,
  Box,
  Grid,
  Badge,
  Alert,
  Accordion,
  LoadingOverlay,
  Divider,
  Loader,
} from "@mantine/core";
import {
  IconFileInvoice,
  IconChevronLeft,
  IconChevronRight,
  IconBuildingHospital,
  IconInfoCircle,
  IconPigMoney,
  IconTable,
} from "@tabler/icons-react";
import {
  BedrockRuntimeClient,
  InvokeModelCommand,
} from "@aws-sdk/client-bedrock-runtime";
import awsconfig from "../../aws-exports";
import { Amplify } from "aws-amplify";
import { DynamoDBClient, QueryCommand } from "@aws-sdk/client-dynamodb";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { marshall, unmarshall } from "@aws-sdk/util-dynamodb";
import { fetchAuthSession } from "aws-amplify/auth";
import BillAdjustmentCalculator from "./BillSavings";

const adjustmentPrompt = `
You are a medical billing expert. Analyze the following billing errors and determine the appropriate adjustment amount.
Current bill total: {{TOTAL}}
Errors: {{ERRORS}}

Rules:
1. For duplicate charges, subtract one instance of the charge
2. For mutually exclusive codes, determine which code is most appropriate based on:
   - Higher specificity
   - Better match for documented procedure
   - Standard medical practice
   Then subtract the charge for the less appropriate code
3. For bundled services, subtract the charge for the service that should be included
4. For coding errors, calculate adjustment based on the correct code's typical charge

Respond with a JSON object containing:
{
  "adjustments": [
    {
      "errorType": "string",
      "description": "string",
      "amount": number
    }
  ],
  "explanation": "string"
}
`;

Amplify.configure(awsconfig);

function Step3({ billAnalysis, preliminaryInfo }) {
  const [selectedBillIndex, setSelectedBillIndex] = useState(null);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);
  const [cashPrices, setCashPrices] = useState({});
  const [expandedItems, setExpandedItems] = useState(["savings"]);
  // New state for storing all bill adjustments
  const [billAdjustments, setBillAdjustments] = useState({});
  const [isLoadingAdjustments, setIsLoadingAdjustments] = useState(true);

  // Calculate total savings across all bills
  const totalSavings = useMemo(() => {
    return Object.values(billAdjustments).reduce(
      (total, adj) => total + (adj.totalAdjustment || 0),
      0
    );
  }, [billAdjustments]);

  const toggleNavbar = () => setNavbarCollapsed(!navbarCollapsed);

  const shouldCheckCashPrices = preliminaryInfo?.insurance === "no insurance";

  const bills = useMemo(
    () =>
      billAnalysis
        ? billAnalysis.map((result) => {
            const totalCharge = result.result.reduce(
              (sum, item) => sum + (item.Charge || 0),
              0
            );
            return {
              billName: result.billName,
              totalCharge,
              details: result.result,
            };
          })
        : [],
    [billAnalysis]
  );
  useEffect(() => {
    const fetchCashPrices = async () => {
      if (!shouldCheckCashPrices || !preliminaryInfo || !bills.length) {
        setCashPrices({});
        return;
      }

      const hospitalName = preliminaryInfo.hospitalId;
      const state = preliminaryInfo.state;

      if (hospitalName === "N/A") {
        console.log("Cash prices not available for this hospital");
        setCashPrices({});
        return;
      }

      try {
        const session = await fetchAuthSession();

        if (!session.tokens) {
          throw new Error("User is not authenticated");
        }

        const currentConfig = Amplify.getConfig();
        const region = currentConfig.Auth?.Cognito?.region || "us-west-2";
        const userPoolId = currentConfig.Auth?.Cognito?.userPoolId;
        const identityPoolId = awsconfig.aws_cognito_identity_pool_id;

        if (!userPoolId || !identityPoolId) {
          throw new Error("Missing required AWS configuration");
        }

        const credentials = fromCognitoIdentityPool({
          clientConfig: { region: region },
          identityPoolId: identityPoolId,
          logins: {
            [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]:
              session.tokens.idToken.toString(),
          },
        });

        const client = new DynamoDBClient({
          region: region,
          credentials: credentials,
        });

        // Collect all unique CPT codes from all bills
        const allCptCodes = new Set();
        bills.forEach((bill) => {
          bill.details.forEach((item) => {
            allCptCodes.add(item["CPT Code"]);
          });
        });

        const queryParams = {
          TableName: "hospital_cash_prices",
          KeyConditionExpression:
            "#state = :stateValue AND begins_with(#hospital, :hospitalValue)",
          ExpressionAttributeNames: {
            "#state": "state",
            "#hospital": "hospital_name_cpt_code_care_type",
          },
          ExpressionAttributeValues: marshall({
            ":stateValue": state,
            ":hospitalValue": hospitalName,
          }),
        };

        const command = new QueryCommand(queryParams);
        const response = await client.send(command);

        if (response.Items && response.Items.length > 0) {
          const items = response.Items.map((item) => unmarshall(item));
          const priceLookup = {};

          for (const item of items) {
            const [itemHospital, itemCptCode, itemCareType] =
              item.hospital_name_cpt_code_care_type.split("#");
            if (allCptCodes.has(itemCptCode)) {
              priceLookup[itemCptCode] = item.cash_price;
            }
          }

          setCashPrices(priceLookup);
        } else {
          setCashPrices({});
        }
      } catch (error) {
        console.error("Error fetching cash prices:", error);
        setCashPrices({});
      }
    };

    fetchCashPrices();
  }, [bills, preliminaryInfo, shouldCheckCashPrices]);

  useEffect(() => {
    const computeAllAdjustments = async () => {
      if (!bills.length) return;

      setIsLoadingAdjustments(true);
      const adjustments = {};

      try {
        const { credentials } = await fetchAuthSession();
        const client = new BedrockRuntimeClient({
          region: "us-west-2",
          credentials: credentials,
        });

        for (const bill of bills) {
          try {
            // Calculate duplicate charge adjustments
            const simpleAdjustments = [];
            const duplicateErrors = bill.details.filter((item) =>
              item["Error Type"]?.toLowerCase().includes("duplicate")
            );

            duplicateErrors.forEach((error) => {
              simpleAdjustments.push({
                errorType: "Duplicate Charge",
                description: `Remove duplicate ${error.Procedure}`,
                amount: parseFloat(error.Charge),
              });
            });

            // Calculate cash price savings if applicable
            const cashSavings = [];
            if (shouldCheckCashPrices && Object.keys(cashPrices).length > 0) {
              bill.details
                // Only check cash prices for items that don't have other errors
                .filter(
                  (item) =>
                    item["Error"] === "N/A" &&
                    !item["Error Type"]?.toLowerCase().includes("duplicate")
                )
                .forEach((item) => {
                  const cashPrice = cashPrices[item["CPT Code"]];
                  const chargedPrice = parseFloat(item.Charge);
                  if (cashPrice && chargedPrice > cashPrice) {
                    const savings = chargedPrice - cashPrice;
                    cashSavings.push({
                      errorType: "Cash Price Savings",
                      description: `Cash price available for ${item.Procedure}`,
                      amount: savings,
                    });
                  }
                });
            }

            // Use LLM for complex cases
            const complexErrors = bill.details.filter(
              (item) =>
                !item["Error Type"]?.toLowerCase().includes("duplicate") &&
                item.Error !== "N/A"
            );

            let llmAdjustments = [];
            if (complexErrors.length > 0) {
              const prompt = adjustmentPrompt
                .replace("{{TOTAL}}", bill.totalCharge)
                .replace("{{ERRORS}}", JSON.stringify(complexErrors));

              const input = {
                modelId: "anthropic.claude-3-5-sonnet-20241022-v2:0",
                contentType: "application/json",
                accept: "application/json",
                body: JSON.stringify({
                  anthropic_version: "bedrock-2023-05-31",
                  max_tokens: 2500,
                  messages: [
                    {
                      role: "user",
                      content: prompt,
                    },
                  ],
                }),
              };

              const command = new InvokeModelCommand(input);
              const result = await client.send(command);
              const response = JSON.parse(
                new TextDecoder().decode(result.body)
              );
              llmAdjustments = JSON.parse(response.content[0].text).adjustments;
            }

            const allAdjustments = [
              ...simpleAdjustments,
              ...llmAdjustments,
              ...cashSavings,
            ];

            const totalAdjustment = allAdjustments.reduce((sum, adj) => {
              const amount =
                typeof adj.amount === "string"
                  ? parseFloat(adj.amount)
                  : adj.amount;
              return sum + (isNaN(amount) ? 0 : Math.abs(amount));
            }, 0);

            adjustments[bill.billName] = {
              adjustments: allAdjustments,
              totalAdjustment,
            };
          } catch (error) {
            console.error(
              `Error calculating adjustments for ${bill.billName}:`,
              error
            );
            adjustments[bill.billName] = {
              adjustments: [],
              totalAdjustment: 0,
            };
          }
        }
      } catch (error) {
        console.error("Error in computeAllAdjustments:", error);
      } finally {
        setBillAdjustments(adjustments);
        setIsLoadingAdjustments(false);
      }
    };

    computeAllAdjustments();
  }, [bills, cashPrices, shouldCheckCashPrices]);

  const formatCurrency = (amount) => `$${amount.toFixed(2)}`;
  const currentDate = new Date().toLocaleDateString();

  const BillButton = ({ billName, isActive, onClick }) => (
    <UnstyledButton
      onClick={onClick}
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color:
          theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
        backgroundColor: isActive
          ? theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[1]
          : "transparent",
        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[1],
        },
      })}
    >
      <Group>
        <ThemeIcon color="blue" variant="light">
          <IconFileInvoice style={{ width: rem(16), height: rem(16) }} />
        </ThemeIcon>
        <Text size="sm">{billName}</Text>
      </Group>
    </UnstyledButton>
  );

  const calculateErrorCount = (bill) => {
    return bill.details.filter((item) => {
      const cashPrice = cashPrices[item["CPT Code"]] || 0;
      const chargedPrice = item["Charge"] || 0;

      // Only include cash price comparison errors if user has no insurance
      const hasCashPriceError =
        shouldCheckCashPrices && cashPrice > 0 && chargedPrice > cashPrice;

      return item["Error"] !== "N/A" || hasCashPriceError;
    }).length;
  };

  const allBillsTable = () => (
    <Stack spacing="md" align="center">
      <Table
        striped
        highlightOnHover
        withBorder
        withColumnBorders
        style={{ width: "100%" }}
        styles={(theme) => ({
          root: {
            border: `1px solid ${theme.colors.blue[6]}`,
            borderRadius: theme.radius.sm,
          },
          th: {
            backgroundColor: theme.colors.blue[0],
            color: theme.colors.blue[9],
            textAlign: "center",
          },
          td: {
            textAlign: "center",
          },
        })}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Bill Name</Table.Th>
            <Table.Th>Original Amount</Table.Th>
            <Table.Th>Potential Savings</Table.Th>
            <Table.Th>Adjusted Amount</Table.Th>
            <Table.Th>Number of Errors</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {bills.map((bill, index) => {
            const errorCount = calculateErrorCount(bill);
            const adjustment = billAdjustments[bill.billName] || {
              totalAdjustment: 0,
            };
            const adjustedAmount =
              bill.totalCharge - adjustment.totalAdjustment;

            return (
              <Table.Tr key={index}>
                <Table.Td>
                  <Anchor
                    component="button"
                    size="sm"
                    color="blue"
                    onClick={() => setSelectedBillIndex(index)}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      "&:hover": {
                        textDecoration: "none",
                      },
                    }}
                  >
                    {bill.billName}
                  </Anchor>
                </Table.Td>
                <Table.Td>{formatCurrency(bill.totalCharge)}</Table.Td>
                <Table.Td style={{ color: "green" }}>
                  -{formatCurrency(adjustment.totalAdjustment)}
                </Table.Td>
                <Table.Td>{formatCurrency(adjustedAmount)}</Table.Td>
                <Table.Td>{errorCount}</Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </Stack>
  );
  const individualBillTable = () => {
    const bill = bills[selectedBillIndex];
    const billAdjustment = billAdjustments[bill.billName] || {
      adjustments: [],
      totalAdjustment: 0,
    };

    const filteredDetails = bill.details
      .filter((item) => {
        const cashPrice = cashPrices[item["CPT Code"]] || 0;
        const chargedPrice = item.Charge || 0;
        const hasCashPriceError =
          shouldCheckCashPrices && cashPrice > 0 && chargedPrice > cashPrice;
        return item["Error"] !== "N/A" || hasCashPriceError;
      })
      .map((item) => {
        const cashPrice = cashPrices[item["CPT Code"]] || 0;
        const chargedPrice = item.Charge || 0;
        if (
          shouldCheckCashPrices &&
          cashPrice > 0 &&
          chargedPrice > cashPrice &&
          item["Error"] === "N/A"
        ) {
          return {
            ...item,
            Error: `You were charged $${chargedPrice.toFixed(
              2
            )} when the cash price is $${cashPrice.toFixed(2)}`,
            "Error Type": "Potential Overcharge",
          };
        }
        return item;
      });

    return (
      <Stack spacing="md">
        <Accordion
          multiple
          value={expandedItems}
          onChange={setExpandedItems}
          variant="contained"
        >
          <Accordion.Item value="savings">
            <Accordion.Control>
              <Group spacing="xs">
                <IconPigMoney size={20} />
                <Text size="lg" fw={500}>
                  Potential Savings Analysis
                </Text>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <BillAdjustmentCalculator
                bill={bill}
                preComputedAdjustments={billAdjustment}
                cashPrices={cashPrices}
                hasInsurance={!shouldCheckCashPrices}
              />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="details">
            <Accordion.Control>
              <Group spacing="xs">
                <IconTable size={20} />
                <Text size="lg" fw={500}>
                  Detailed Error Analysis
                </Text>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <Table
                striped
                highlightOnHover
                withBorder
                withColumnBorders
                styles={(theme) => ({
                  root: {
                    border: `1px solid ${theme.colors.blue[6]}`,
                    borderRadius: theme.radius.sm,
                  },
                  th: {
                    backgroundColor: theme.colors.blue[0],
                    color: theme.colors.blue[9],
                    textAlign: "center",
                  },
                  td: {
                    textAlign: "center",
                  },
                })}
              >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>CPT Code</Table.Th>
                    <Table.Th>Procedure</Table.Th>
                    <Table.Th>Charged Price</Table.Th>
                    <Table.Th>Cash Price</Table.Th>
                    <Table.Th>Error</Table.Th>
                    <Table.Th>Error Type</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {filteredDetails.length > 0 ? (
                    filteredDetails.map((item, index) => (
                      <Table.Tr key={index}>
                        <Table.Td>{item["CPT Code"]}</Table.Td>
                        <Table.Td>{item["Procedure"]}</Table.Td>
                        <Table.Td>{formatCurrency(item["Charge"])}</Table.Td>
                        <Table.Td>
                          {shouldCheckCashPrices && cashPrices[item["CPT Code"]]
                            ? formatCurrency(cashPrices[item["CPT Code"]])
                            : "N/A"}
                        </Table.Td>
                        <Table.Td>{item["Error"]}</Table.Td>
                        <Table.Td>{item["Error Type"]}</Table.Td>
                      </Table.Tr>
                    ))
                  ) : (
                    <Table.Tr>
                      <Table.Td colSpan={6} style={{ textAlign: "center" }}>
                        No errors or price discrepancies found in this bill.
                      </Table.Td>
                    </Table.Tr>
                  )}
                </Table.Tbody>
              </Table>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Stack>
    );
  };
  return (
    <Box mt="xl" pos="relative">
      {/* Loading overlay */}
      {isLoadingAdjustments && (
        <Box
          sx={(theme) => ({
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          <Stack align="center" spacing="xl">
            <Text size="xl" fw={500} c="blue.7">
              Using AI to analyze your bills...
            </Text>
            <Loader size="xl" color="blue" />
          </Stack>
        </Box>
      )}
      <Grid
        gutter="md"
        style={{
          filter: isLoadingAdjustments ? "blur(3px)" : "none",
          transition: "filter 0.3s ease",
        }}
      >
        {/* Left Sidebar */}
        <Grid.Col
          span={navbarCollapsed ? 1 : 3}
          style={{ maxWidth: navbarCollapsed ? 80 : 300 }}
        >
          <Paper shadow="sm" radius="md" p="md" style={{ height: "100%" }}>
            <Flex direction="column" h="100%">
              <Group position="apart" mb="md">
                {!navbarCollapsed && (
                  <Text fw={500} size="sm" color="dimmed">
                    BILLS LIST
                  </Text>
                )}
                <UnstyledButton onClick={toggleNavbar}>
                  <ThemeIcon color="blue" variant="light" size="sm">
                    {navbarCollapsed ? (
                      <IconChevronRight size={rem(16)} />
                    ) : (
                      <IconChevronLeft size={rem(16)} />
                    )}
                  </ThemeIcon>
                </UnstyledButton>
              </Group>
              <ScrollArea style={{ flex: 1 }}>
                {!navbarCollapsed && (
                  <Stack spacing="xs">
                    <Paper
                      p="xs"
                      withBorder
                      bg={selectedBillIndex === null ? "blue.0" : "transparent"}
                      sx={(theme) => ({
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: theme.colors.gray[0],
                        },
                      })}
                      onClick={() => setSelectedBillIndex(null)}
                    >
                      <Group>
                        <ThemeIcon color="blue" variant="light">
                          <IconFileInvoice
                            style={{ width: rem(16), height: rem(16) }}
                          />
                        </ThemeIcon>
                        <Box>
                          <Text size="sm" fw={500}>
                            All Bills
                          </Text>
                          <Text size="xs" color="dimmed">
                            Total:{" "}
                            {formatCurrency(
                              bills.reduce(
                                (sum, bill) => sum + bill.totalCharge,
                                0
                              )
                            )}
                          </Text>
                        </Box>
                      </Group>
                    </Paper>
                    {bills.map((bill, index) => (
                      <Paper
                        key={index}
                        p="xs"
                        withBorder
                        bg={
                          index === selectedBillIndex ? "blue.0" : "transparent"
                        }
                        sx={(theme) => ({
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: theme.colors.gray[0],
                          },
                        })}
                        onClick={() => setSelectedBillIndex(index)}
                      >
                        <Group>
                          <ThemeIcon color="blue" variant="light">
                            <IconFileInvoice
                              style={{ width: rem(16), height: rem(16) }}
                            />
                          </ThemeIcon>
                          <Box>
                            <Text size="sm" fw={500}>
                              {bill.billName}
                            </Text>
                            <Text size="xs" color="dimmed">
                              {formatCurrency(bill.totalCharge)}
                            </Text>
                          </Box>
                        </Group>
                      </Paper>
                    ))}
                  </Stack>
                )}
              </ScrollArea>
            </Flex>
          </Paper>
        </Grid.Col>

        <Grid.Col span={navbarCollapsed ? 11 : 9}>
          <Stack spacing="md">
            {/* Summary Card */}
            {selectedBillIndex === null && (
              <Paper shadow="sm" radius="md" p="md" withBorder>
                <Group position="apart" mb="xs">
                  <Group spacing="xs">
                    <IconBuildingHospital size={24} stroke={1.5} color="blue" />
                    <Title order={4}>
                      {preliminaryInfo
                        ? preliminaryInfo.hospital
                        : "Not Selected"}
                    </Title>
                  </Group>
                  <Badge size="lg" variant="outline">
                    {currentDate}
                  </Badge>
                </Group>
                <Stack spacing="xs">
                  <Group position="apart">
                    <Text size="lg" fw={500} color="dimmed">
                      Total Amount:
                    </Text>
                    <Text size="xl" fw={700} color="blue">
                      {formatCurrency(
                        bills.reduce((sum, bill) => sum + bill.totalCharge, 0)
                      )}
                    </Text>
                  </Group>
                  <Group position="apart">
                    <Text size="lg" fw={500} color="dimmed">
                      Total Potential Savings:
                    </Text>
                    <Text size="xl" fw={700} c="green">
                      -{formatCurrency(totalSavings)}
                    </Text>
                  </Group>
                  <Divider my="xs" />
                  <Group position="apart">
                    <Text size="lg" fw={500} color="dimmed">
                      Estimated New Total:
                    </Text>
                    <Text size="xl" fw={700} color="blue">
                      {formatCurrency(
                        bills.reduce((sum, bill) => sum + bill.totalCharge, 0) -
                          totalSavings
                      )}
                    </Text>
                  </Group>
                </Stack>
              </Paper>
            )}

            {selectedBillIndex === null && (
              <Alert color="blue" radius="md">
                <Group>
                  <ThemeIcon color="blue" size="lg" variant="light">
                    <IconInfoCircle size={20} />
                  </ThemeIcon>
                  {/* <Text fw={700} c="blue.7">
                    {selectedBillIndex === null
                      ? "Below is a summary of any potential savings we have identified. Click on any bill for more details."
                      : "Below is a detailed breakdown of errors and potential savings we found in this bill."}
                  </Text> */}
                  <Text fw={700} c="blue.7">
                    Below is a summary of any potential savings we have
                    identified. Click on any bill for more details.
                  </Text>
                </Group>
              </Alert>
            )}

            {/* Bills Table */}
            <Paper shadow="sm" radius="md" withBorder>
              {selectedBillIndex === null
                ? allBillsTable()
                : individualBillTable()}
            </Paper>
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
export default Step3;
