import React, { useState, useEffect } from "react";
import {
  BedrockRuntimeClient,
  InvokeModelCommand,
} from "@aws-sdk/client-bedrock-runtime";
import { Amplify } from "aws-amplify";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { uploadData } from "@aws-amplify/storage";
import { generateClient } from "@aws-amplify/api";
import {
  Text,
  Stack,
  Paper,
  Title,
  Flex,
  Select,
  Button,
  FileInput,
  Group,
  TextInput,
  LoadingOverlay,
  Box,
  Divider,
} from "@mantine/core";
import awsconfig from "../../aws-exports";
import * as mutations from "../../graphql/mutations";

Amplify.configure(awsconfig);

const extractPrompt = `
You are a medical billing expert with expert vision and an eye for detail.

**Task**: Extract all CPT codes visible in the provided image(s) along with their associated charges.

**Instructions**:
- Respond ONLY with a valid JSON object.
- The JSON should have the key "cpt_codes" and the value should be an array of objects, each containing a "code" and a "charge".
- The "charge" should be a numeric value without currency symbols.
- Do not include any explanations, introductions, or additional text.
- Do not include code snippets or markdown formatting.
- Ensure the JSON is valid and parsable.
- If multiple images are provided, consider them as part of the same bill and extract all unique CPT codes with their charges.

**Constraints**:
You must begin your response with { and end it with }.
**Example**:
{
    "cpt_codes": [
        {"code": "12345", "charge": 150.00},
        {"code": "67890", "charge": 200.50}
    ]
}
`;

const analysisPrompt = `
Analyze the following CPT codes and charges extracted from the image(s): {{CODES}}

Here are some relevant rules and information for each code from our reference data:
{{RULES}}

Based on these rules and your knowledge of CPT coding:
1. Identify any potential errors in the use of these CPT codes.
2. Consider factors such as:
   a. Codes used together that shouldn't be
   b. Missing required additional codes
   c. Inappropriate use based on the visible medical context
   d. Unusually high or low charges for specific codes

Note: While the provided rules are a useful reference, they may not be exhaustive or up-to-date. Use your own knowledge and judgment as well.
The most reliable rules are those that specify two codes that should not be used together or that require a specific additional code.
Pay special attention to these types of rules when analyzing the codes.

Respond with a JSON array where each item has the following properties: 
"CPT Code", "Charge", "Procedure", "CPT Code Explanation", "Layman Explanation", "Error", and "Error Type".

For the "Layman Explanation", provide a clear, simple explanation that a non-medical person can understand.
The explanation should be easy to understand, but also detailed enough for the patient to understand whether they got 
the correct service or not. Ideally it should be 2 or 3 sentences long.

**Constraints**:
You must begin your response with [ and end it with ] no matter what. 
Do not include any explanatory text before or after the JSON array.

Example:
[
  {
    "CPT Code": "99213",
    "Charge": 150.00,
    "Procedure": "Office visit",
    "CPT Code Explanation": "Level three office visit with an established patient typically 20 minutes or longer",
    "Layman Explanation": "A medium-length follow-up visit with your regular doctor, usually lasting about 20 minutes",
    "Error": "Duplicate charge",
    "Error Type": "Billing the same service twice"
  }
]

**important**
If there is no error, return the string "N/A" for both "Error" and "Error Type".

Ensure the response is valid JSON that can be parsed without errors. This is critical.
`;

function BillAnalysis({ preliminaryInfoId, setBillAnalysis, nextStep }) {
  const [bills, setBills] = useState([
    { name: "", documents: [{ type: "", file: null }] },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cptCodesData, setCptCodesData] = useState(null);
  const [analysisResults, setAnalysisResults] = useState([]);

  useEffect(() => {
    // Load CPT codes data
    fetch("/data/consolidated_cpt_codes_cleaned.json")
      .then((response) => response.json())
      .then((data) => setCptCodesData(data))
      .catch((error) => console.error("Error loading CPT codes data:", error));
  }, []);

  const callClaude = async (prompt, base64Images) => {
    const { credentials } = await fetchAuthSession();

    const client = new BedrockRuntimeClient({
      region: "us-west-2",
      credentials: credentials,
    });

    const input = {
      modelId: "anthropic.claude-3-5-sonnet-20241022-v2:0",
      contentType: "application/json",
      accept: "application/json",
      body: JSON.stringify({
        anthropic_version: "bedrock-2023-05-31",
        max_tokens: 2500,
        messages: [
          {
            role: "user",
            content: [
              { type: "text", text: prompt },
              ...base64Images.map((base64Image) => ({
                type: "image",
                source: {
                  type: "base64",
                  media_type: "image/jpeg",
                  data: base64Image,
                },
              })),
            ],
          },
        ],
      }),
    };

    const command = new InvokeModelCommand(input);
    const result = await client.send(command);

    const responseBody = JSON.parse(new TextDecoder().decode(result.body));
    console.log("Raw response from Claude:", responseBody);

    return JSON.parse(responseBody.content[0].text);
  };

  const analyzeBills = async () => {
    setLoading(true);
    setError(null);
    setAnalysisResults([]);

    if (!preliminaryInfoId) {
      setError("Preliminary information is required before analyzing bills.");
      setLoading(false);
      return;
    }

    for (const bill of bills) {
      if (!bill.name || bill.documents.some((doc) => !doc.file)) {
        setError("Please provide a name and upload documents for all bills.");
        setLoading(false);
        return;
      }
    }

    try {
      const newAnalysisResults = [];
      for (const bill of bills) {
        const base64Images = await Promise.all(
          bill.documents.map((doc) => convertToBase64(doc.file))
        );

        // First Claude call: Extract CPT codes and charges
        const extractedCodes = await callClaude(extractPrompt, base64Images);
        console.log("Extracted CPT codes and charges:", extractedCodes);

        // Prepare relevant rules for extracted codes
        const relevantRules = {};
        for (const { code } of extractedCodes.cpt_codes) {
          if (cptCodesData && cptCodesData[code]) {
            relevantRules[code] = cptCodesData[code].rules || [
              "No specific rules found for this code.",
            ];
          } else {
            relevantRules[code] = [
              "No rules found in the reference data for this code.",
            ];
          }
        }

        // Prepare the analysis prompt
        const analysisPromptWithData = analysisPrompt
          .replace("{{CODES}}", JSON.stringify(extractedCodes.cpt_codes))
          .replace("{{RULES}}", JSON.stringify(relevantRules, null, 2));

        // Second Claude call: Analyze CPT codes
        const analysisResult = await callClaude(analysisPromptWithData, []);
        console.log("Analysis result:", analysisResult);

        // Pass preliminaryInfoId to storeBillErrorsInDynamoDB
        await storeBillErrorsInDynamoDB(
          analysisResult,
          bill.name,
          preliminaryInfoId
        );

        newAnalysisResults.push({
          billName: bill.name,
          result: analysisResult,
        });
      }
      setAnalysisResults(newAnalysisResults);
      console.log("All analysis results:", newAnalysisResults);
      setBillAnalysis(newAnalysisResults);
    } catch (error) {
      console.error("Error analyzing bills:", error);
      setError(
        error.message ||
          "An error occurred while analyzing the bills. Please try again."
      );
    } finally {
      setLoading(false);
      nextStep();
    }
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDocumentUpload = async (file, billIndex, docIndex) => {
    const newBills = [...bills];
    newBills[billIndex].documents[docIndex].file = file;
    setBills(newBills);

    try {
      const result = await uploadData({
        path: `public/${file.name}`,
        data: file,
        options: {
          contentType: file.type,
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
          },
        },
      });

      console.log("Path from Response: ", result.path);
    } catch (error) {
      console.error("Error uploading image: ", error);
      setError("Error uploading image. Please try again.");
    }
  };
  // async function storeBillErrorsInDynamoDB(
  //   billErrors,
  //   billName,
  //   preliminaryInfoId
  // ) {
  //   const apiClient = generateClient();
  //   const { userId } = await getCurrentUser();

  //   try {
  //     // First create the Bill record to link preliminary info
  //     const createBillResult = await apiClient.graphql({
  //       query: mutations.createBill,
  //       variables: {
  //         input: {
  //           userId,
  //           preliminaryInfoId,
  //           billName,
  //           createdAt: new Date().toISOString(),
  //         },
  //       },
  //     });

  //     const billId = createBillResult.data.createBill.id;

  //     // Then create all the BillErrors using the existing structure
  //     const results = await Promise.all(
  //       billErrors.map((error) => {
  //         const input = {
  //           userId,
  //           cptCode: error["CPT Code"],
  //           procedure: error["Procedure"],
  //           error: error["Error"] || null,
  //           errorType: error["Error Type"] || null,
  //           billName, // This links to the bill by name
  //         };

  //         return apiClient.graphql({
  //           query: mutations.createBillErrors, // Note: using existing BillErrors mutation
  //           variables: { input },
  //         });
  //       })
  //     );

  //     console.log("Successfully stored bill and errors:", {
  //       bill: createBillResult.data.createBill,
  //       errors: results.map((r) => r.data.createBillErrors),
  //     });
  //   } catch (error) {
  //     console.error("Error storing bill data:", error);
  //     throw error;
  //   }
  // }

  async function storeBillErrorsInDynamoDB(
    billErrors,
    billName,
    preliminaryInfoId
  ) {
    const apiClient = generateClient();
    const { userId } = await getCurrentUser();

    try {
      // First create a Case record
      const createCaseResult = await apiClient.graphql({
        query: mutations.createCase,
        variables: {
          input: {
            userId,
            preliminaryInfoId,
            status: "IN_PROGRESS",
            createdAt: new Date().toISOString(),
          },
        },
      });

      const caseId = createCaseResult.data.createCase.id;

      // Then create the Bill record and link it to the Case
      const createBillResult = await apiClient.graphql({
        query: mutations.createBill,
        variables: {
          input: {
            userId,
            preliminaryInfoId,
            caseId, // Link to the case
            billName,
            createdAt: new Date().toISOString(),
          },
        },
      });

      const billId = createBillResult.data.createBill.id;

      // Then create all the BillErrors using the existing structure
      const results = await Promise.all(
        billErrors.map((error) => {
          const input = {
            userId,
            cptCode: error["CPT Code"],
            procedure: error["Procedure"],
            error: error["Error"] || null,
            errorType: error["Error Type"] || null,
            billName, // This links to the bill by name
          };

          return apiClient.graphql({
            query: mutations.createBillErrors,
            variables: { input },
          });
        })
      );

      console.log("Successfully stored case, bill and errors:", {
        case: createCaseResult.data.createCase,
        bill: createBillResult.data.createBill,
        errors: results.map((r) => r.data.createBillErrors),
      });
    } catch (error) {
      console.error("Error storing bill data:", error);
      throw error;
    }
  }

  const addDocument = (billIndex) => {
    const newBills = [...bills];
    newBills[billIndex].documents.push({ type: "", file: null });
    setBills(newBills);
  };

  const addBill = () => {
    setBills([...bills, { name: "", documents: [{ type: "", file: null }] }]);
  };

  return (
    <>
      <Flex align="center" justify="center">
        <Paper
          shadow="xs"
          radius="lg"
          withBorder
          p="xl"
          mx="lg"
          mt="sm"
          w="85%"
          h="auto"
        >
          <Title order={4}>Upload and Analyze Medical Bills</Title>
          <Text c="dimmed" size="md">
            Please upload your medical billing documents below.
          </Text>
          <Stack spacing="xl" mt="lg">
            <LoadingOverlay visible={loading} overlayBlur={2} />
            {bills.map((bill, billIndex) => (
              <React.Fragment key={billIndex}>
                {billIndex > 0 && <Divider my="xl" />}
                <Stack spacing="md">
                  <TextInput
                    label={`Bill ${billIndex + 1} Name`}
                    value={bill.name}
                    onChange={(event) => {
                      const newBills = [...bills];
                      newBills[billIndex].name = event.currentTarget.value;
                      setBills(newBills);
                    }}
                    required
                  />
                  {bill.documents.map((doc, docIndex) => (
                    <Group key={docIndex} grow>
                      <Select
                        label="Document Type"
                        placeholder="Select document type"
                        value={doc.type}
                        searchable
                        onChange={(value) => {
                          const newBills = [...bills];
                          newBills[billIndex].documents[docIndex].type = value;
                          setBills(newBills);
                        }}
                        data={[
                          { value: "itemized_bill", label: "Itemized Bill" },
                          {
                            value: "explanation_of_benefits",
                            label: "Explanation of Benefits",
                          },
                          { value: "other", label: "Other" },
                        ]}
                      />
                      <FileInput
                        label="Upload Document"
                        placeholder="Upload or drop files here"
                        accept="image/png,image/jpeg,application/pdf"
                        value={doc.file}
                        onChange={(file) =>
                          handleDocumentUpload(file, billIndex, docIndex)
                        }
                      />
                    </Group>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "200px",
                      margin: "auto",
                    }}
                  >
                    <Button
                      onClick={() => addDocument(billIndex)}
                      color="blue"
                      fullWidth={false}
                    >
                      Add another document
                    </Button>
                  </Box>
                </Stack>
              </React.Fragment>
            ))}
          </Stack>
          {error && (
            <Text color="red" mt="md">
              {error}
            </Text>
          )}
        </Paper>
      </Flex>

      {/* Buttons Outside the Paper */}
      <Flex justify="center" align="center" mt="xl" gap="md">
        <Box sx={{ width: "200px", display: "flex", justifyContent: "center" }}>
          <Button onClick={addBill} color="blue" fullWidth={false}>
            Add another bill
          </Button>
        </Box>
        <Box sx={{ width: "200px", display: "flex", justifyContent: "center" }}>
          <Button
            onClick={analyzeBills}
            color="green"
            disabled={loading}
            fullWidth={false}
          >
            Analyze Bills
          </Button>
        </Box>
      </Flex>
    </>
  );
}

export default BillAnalysis;
