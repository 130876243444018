import React from "react";
import { Paper, Stack, Group, Text, Divider, Box } from "@mantine/core";
import { IconMinus } from "@tabler/icons-react";

const BillAdjustmentCalculator = ({
  bill,
  preComputedAdjustments,
  cashPrices,
  hasInsurance,
}) => {
  // Use pre-computed values
  const { adjustments, totalAdjustment } = preComputedAdjustments;

  const formatCurrency = (amount) => `$${amount.toFixed(2)}`;

  return (
    <Paper shadow="sm" p="md" radius="md" withBorder pos="relative" mb="xl">
      <Stack spacing="md">
        <Paper p="md" radius="md" bg="blue.0">
          <Group position="apart">
            <Text size="xl" fw={600}>
              {bill.billName} Total:
            </Text>
            <Text size="xl" fw={700}>
              {formatCurrency(bill.totalCharge)}
            </Text>
          </Group>
        </Paper>
        {adjustments.map((adjustment, index) => (
          <Group key={index} position="apart" p="xs">
            <Group spacing="sm">
              {/* <IconMinus size={16} color="red" /> */}
              <Box style={{ width: "100px", textAlign: "right" }}>
                <Text fw={500} color="red">
                  -${Math.abs(adjustment.amount).toFixed(2)}
                </Text>
              </Box>
              <Text>{adjustment.description}</Text>
            </Group>
          </Group>
        ))}
        <Divider my="sm" />
        {/* <Paper p="md" radius="md" bg="red.0"> */}
          <Group position="apart">
            <Text size="lg" fw={600}>
              Potential Savings:
            </Text>
            <Text size="lg" fw={700} >
              -${Math.abs(totalAdjustment).toFixed(2)}
            </Text>
          </Group>
          {/* </Paper> */}
        {/* <Paper p="md" radius="md" bg="blue.0"> */}
          <Group position="apart">
            <Text size="lg" fw={600}>
              Adjusted Bill Amount:
            </Text>
            <Text size="xl" fw={700} color="green">
              {formatCurrency(bill.totalCharge - totalAdjustment)}
            </Text>
          </Group>
        {/* </Paper> */}
      </Stack>
    </Paper>
  );
};

export default BillAdjustmentCalculator;

// import React, { useState, useEffect } from "react";
// import {
//   Paper,
//   Stack,
//   Group,
//   Text,
//   Divider,
//   LoadingOverlay,
//   Box,
// } from "@mantine/core";
// import { IconMinus } from "@tabler/icons-react";
// import {
//   BedrockRuntimeClient,
//   InvokeModelCommand,
// } from "@aws-sdk/client-bedrock-runtime";
// import { fetchAuthSession } from "aws-amplify/auth";

// const adjustmentPrompt = `
// You are a medical billing expert. Analyze the following billing errors and determine the appropriate adjustment amount.
// Current bill total: {{TOTAL}}
// Errors: {{ERRORS}}

// Rules:
// 1. For duplicate charges, subtract one instance of the charge
// 2. For mutually exclusive codes, determine which code is most appropriate based on:
//    - Higher specificity
//    - Better match for documented procedure
//    - Standard medical practice
//    Then subtract the charge for the less appropriate code
// 3. For bundled services, subtract the charge for the service that should be included
// 4. For coding errors, calculate adjustment based on the correct code's typical charge

// Respond with a JSON object containing:
// {
//   "adjustments": [
//     {
//       "errorType": "string",
//       "description": "string",
//       "amount": number
//     }
//   ],
//   "explanation": "string"
// }
// `;

// const BillAdjustmentCalculator = ({ bill, cashPrices, hasInsurance }) => {
//   const [adjustments, setAdjustments] = useState([]);
//   const [totalAdjustment, setTotalAdjustment] = useState(0);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchAdjustments = async () => {
//       setIsLoading(true);
//       try {
//         // Handle simple cases without LLM
//         const simpleAdjustments = [];

//         // Calculate duplicate charge adjustments
//         const duplicateErrors = bill.details.filter((item) =>
//           item["Error Type"]?.toLowerCase().includes("duplicate")
//         );

//         duplicateErrors.forEach((error) => {
//           simpleAdjustments.push({
//             errorType: "Duplicate Charge",
//             description: `Remove duplicate ${error.Procedure}`,
//             amount: parseFloat(error.Charge),
//           });
//         });

//         // Calculate cash price savings if applicable
//         const cashSavings = [];
//         if (!hasInsurance && Object.keys(cashPrices).length > 0) {
//           bill.details.forEach((item) => {
//             const cashPrice = cashPrices[item["CPT Code"]];
//             const chargedPrice = parseFloat(item.Charge);
//             if (cashPrice && chargedPrice > cashPrice) {
//               const savings = chargedPrice - cashPrice;
//               cashSavings.push({
//                 errorType: "Cash Price Savings",
//                 description: `Cash price available for ${item.Procedure}`,
//                 amount: savings,
//               });
//             }
//           });
//         }

//         // Use LLM for complex cases
//         const complexErrors = bill.details.filter(
//           (item) =>
//             !item["Error Type"]?.toLowerCase().includes("duplicate") &&
//             item.Error !== "N/A"
//         );

//         let llmAdjustments = [];
//         if (complexErrors.length > 0) {
//           try {
//             const { credentials } = await fetchAuthSession();
//             const client = new BedrockRuntimeClient({
//               region: "us-west-2",
//               credentials: credentials,
//             });

//             const prompt = adjustmentPrompt
//               .replace("{{TOTAL}}", bill.totalCharge)
//               .replace("{{ERRORS}}", JSON.stringify(complexErrors));

//             const input = {
//               modelId: "anthropic.claude-3-5-sonnet-20241022-v2:0",
//               contentType: "application/json",
//               accept: "application/json",
//               body: JSON.stringify({
//                 anthropic_version: "bedrock-2023-05-31",
//                 max_tokens: 2500,
//                 messages: [
//                   {
//                     role: "user",
//                     content: prompt,
//                   },
//                 ],
//               }),
//             };

//             const command = new InvokeModelCommand(input);
//             const result = await client.send(command);
//             const response = JSON.parse(new TextDecoder().decode(result.body));
//             llmAdjustments = JSON.parse(response.content[0].text).adjustments;
//           } catch (error) {
//             console.error("Error calculating complex adjustments:", error);
//           }
//         }

//         console.log("Simple adjustments:", simpleAdjustments);
//         console.log("Cash savings:", cashSavings);
//         console.log("LLM adjustments:", llmAdjustments);

//         const allAdjustments = [
//           ...simpleAdjustments,
//           ...llmAdjustments,
//           ...cashSavings,
//         ];
//         setAdjustments(allAdjustments);

//         // Fixed: Calculate total adjustment as a positive number
//         const total = allAdjustments.reduce((sum, adj) => {
//           const amount =
//             typeof adj.amount === "string"
//               ? parseFloat(adj.amount)
//               : adj.amount;
//           return sum + (isNaN(amount) ? 0 : Math.abs(amount));
//         }, 0);

//         console.log("Total adjustment calculated:", total);
//         setTotalAdjustment(total);
//       } catch (error) {
//         console.error("Error in fetchAdjustments:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchAdjustments();
//   }, [bill, cashPrices, hasInsurance]);

//   const formatCurrency = (amount) => `$${amount.toFixed(2)}`;

//   return (
//     <Paper shadow="sm" p="md" radius="md" withBorder pos="relative" mb="xl">
//       <LoadingOverlay
//         visible={isLoading}
//         overlayBlur={2}
//         loaderProps={{ size: "xl", color: "blue" }}
//       />
//       <Stack spacing="md">
//         <Paper p="md" radius="md" bg="blue.0">
//           <Group position="apart">
//             <Text size="xl" fw={600}>
//               Current Bill Total:
//             </Text>
//             <Text size="xl" fw={700}>
//               {formatCurrency(bill.totalCharge)}
//             </Text>
//           </Group>
//         </Paper>
//         {adjustments.map((adjustment, index) => (
//           <Group key={index} position="apart" p="xs">
//             <Group spacing="sm">
//               <IconMinus size={16} color="red" />
//               <Text>{adjustment.description}</Text>
//             </Group>
//             <Text fw={500} color="red">
//               -${Math.abs(adjustment.amount).toFixed(2)}
//             </Text>
//           </Group>
//         ))}
//         // Fixed: Add Divider between adjustments and total savings
//         <Divider my="sm" />
//         <Paper p="md" radius="md" bg="red.0">
//           <Group position="apart">
//             <Text size="lg" fw={600}>
//               Total Potential Savings:
//             </Text>
//             <Text size="lg" fw={700} c="green">
//               -${Math.abs(totalAdjustment).toFixed(2)}
//             </Text>
//           </Group>
//         </Paper>
//         <Paper p="md" radius="md" bg="blue.0">
//           <Group position="apart">
//             <Text size="lg" fw={600}>
//               Adjusted Bill Amount:
//             </Text>
//             <Text size="lg" fw={700} color="blue">
//               {formatCurrency(bill.totalCharge - totalAdjustment)}
//             </Text>
//           </Group>
//         </Paper>
//       </Stack>
//     </Paper>
//   );
// };

// export default BillAdjustmentCalculator;
