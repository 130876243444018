import React, { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
import { useNavigate } from "react-router-dom";

Amplify.configure(awsExports);

export default function Signup() {
  const navigate = useNavigate();

  return (
    <Authenticator>
      {({ signOut, user }) => {
        if (user) {
          navigate("/signup_storage");
        }

        return (
          <main>
            {user ? (
              <>
                <h1>Welcome {user.username}</h1>
                <button onClick={signOut}>Sign out</button>
              </>
            ) : (
              <h1>Please sign up or log in</h1>
            )}
          </main>
        );
      }}
    </Authenticator>
  );
}
