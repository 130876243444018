/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      userId
      hospital
      insuranceType
      insurer
      age
      first_name
      last_name
      billAmount
      selectedServices
      serviceDescriptions
      inCollections
      s3ImageKey
      images {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      userId
      hospital
      insuranceType
      insurer
      age
      first_name
      last_name
      billAmount
      selectedServices
      serviceDescriptions
      inCollections
      s3ImageKey
      images {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      userId
      hospital
      insuranceType
      insurer
      age
      first_name
      last_name
      billAmount
      selectedServices
      serviceDescriptions
      inCollections
      s3ImageKey
      images {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserImage = /* GraphQL */ `
  mutation CreateUserImage(
    $input: CreateUserImageInput!
    $condition: ModelUserImageConditionInput
  ) {
    createUserImage(input: $input, condition: $condition) {
      id
      userProfileId
      s3ImageKey
      userId
      userProfile {
        id
        userId
        hospital
        insuranceType
        insurer
        age
        first_name
        last_name
        billAmount
        selectedServices
        serviceDescriptions
        inCollections
        s3ImageKey
        createdAt
        updatedAt
        __typename
      }
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserImage = /* GraphQL */ `
  mutation UpdateUserImage(
    $input: UpdateUserImageInput!
    $condition: ModelUserImageConditionInput
  ) {
    updateUserImage(input: $input, condition: $condition) {
      id
      userProfileId
      s3ImageKey
      userId
      userProfile {
        id
        userId
        hospital
        insuranceType
        insurer
        age
        first_name
        last_name
        billAmount
        selectedServices
        serviceDescriptions
        inCollections
        s3ImageKey
        createdAt
        updatedAt
        __typename
      }
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserImage = /* GraphQL */ `
  mutation DeleteUserImage(
    $input: DeleteUserImageInput!
    $condition: ModelUserImageConditionInput
  ) {
    deleteUserImage(input: $input, condition: $condition) {
      id
      userProfileId
      s3ImageKey
      userId
      userProfile {
        id
        userId
        hospital
        insuranceType
        insurer
        age
        first_name
        last_name
        billAmount
        selectedServices
        serviceDescriptions
        inCollections
        s3ImageKey
        createdAt
        updatedAt
        __typename
      }
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFederalPovertyLimits = /* GraphQL */ `
  mutation CreateFederalPovertyLimits(
    $input: CreateFederalPovertyLimitsInput!
    $condition: ModelFederalPovertyLimitsConditionInput
  ) {
    createFederalPovertyLimits(input: $input, condition: $condition) {
      id
      year
      state
      householdSize
      income
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const updateFederalPovertyLimits = /* GraphQL */ `
  mutation UpdateFederalPovertyLimits(
    $input: UpdateFederalPovertyLimitsInput!
    $condition: ModelFederalPovertyLimitsConditionInput
  ) {
    updateFederalPovertyLimits(input: $input, condition: $condition) {
      id
      year
      state
      householdSize
      income
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const deleteFederalPovertyLimits = /* GraphQL */ `
  mutation DeleteFederalPovertyLimits(
    $input: DeleteFederalPovertyLimitsInput!
    $condition: ModelFederalPovertyLimitsConditionInput
  ) {
    deleteFederalPovertyLimits(input: $input, condition: $condition) {
      id
      year
      state
      householdSize
      income
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const createHospitalPolicies = /* GraphQL */ `
  mutation CreateHospitalPolicies(
    $input: CreateHospitalPoliciesInput!
    $condition: ModelHospitalPoliciesConditionInput
  ) {
    createHospitalPolicies(input: $input, condition: $condition) {
      id
      hospitalName
      hospitalState
      thresholdBottom
      thresholdTop
      typeOfCare
      percentage
      percentageDescription
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const updateHospitalPolicies = /* GraphQL */ `
  mutation UpdateHospitalPolicies(
    $input: UpdateHospitalPoliciesInput!
    $condition: ModelHospitalPoliciesConditionInput
  ) {
    updateHospitalPolicies(input: $input, condition: $condition) {
      id
      hospitalName
      hospitalState
      thresholdBottom
      thresholdTop
      typeOfCare
      percentage
      percentageDescription
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const deleteHospitalPolicies = /* GraphQL */ `
  mutation DeleteHospitalPolicies(
    $input: DeleteHospitalPoliciesInput!
    $condition: ModelHospitalPoliciesConditionInput
  ) {
    deleteHospitalPolicies(input: $input, condition: $condition) {
      id
      hospitalName
      hospitalState
      thresholdBottom
      thresholdTop
      typeOfCare
      percentage
      percentageDescription
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const createApplicationRequirements = /* GraphQL */ `
  mutation CreateApplicationRequirements(
    $input: CreateApplicationRequirementsInput!
    $condition: ModelApplicationRequirementsConditionInput
  ) {
    createApplicationRequirements(input: $input, condition: $condition) {
      id
      hospitalName
      requirement
      s3Url
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const updateApplicationRequirements = /* GraphQL */ `
  mutation UpdateApplicationRequirements(
    $input: UpdateApplicationRequirementsInput!
    $condition: ModelApplicationRequirementsConditionInput
  ) {
    updateApplicationRequirements(input: $input, condition: $condition) {
      id
      hospitalName
      requirement
      s3Url
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const deleteApplicationRequirements = /* GraphQL */ `
  mutation DeleteApplicationRequirements(
    $input: DeleteApplicationRequirementsInput!
    $condition: ModelApplicationRequirementsConditionInput
  ) {
    deleteApplicationRequirements(input: $input, condition: $condition) {
      id
      hospitalName
      requirement
      s3Url
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const createBillErrors = /* GraphQL */ `
  mutation CreateBillErrors(
    $input: CreateBillErrorsInput!
    $condition: ModelBillErrorsConditionInput
  ) {
    createBillErrors(input: $input, condition: $condition) {
      id
      cptCode
      procedure
      error
      errorType
      billName
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const updateBillErrors = /* GraphQL */ `
  mutation UpdateBillErrors(
    $input: UpdateBillErrorsInput!
    $condition: ModelBillErrorsConditionInput
  ) {
    updateBillErrors(input: $input, condition: $condition) {
      id
      cptCode
      procedure
      error
      errorType
      billName
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const deleteBillErrors = /* GraphQL */ `
  mutation DeleteBillErrors(
    $input: DeleteBillErrorsInput!
    $condition: ModelBillErrorsConditionInput
  ) {
    deleteBillErrors(input: $input, condition: $condition) {
      id
      cptCode
      procedure
      error
      errorType
      billName
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;

// export const createPreliminaryInfo = /* GraphQL */ `
//   mutation CreatePreliminaryInfo(
//     $input: CreatePreliminaryInfoInput!
//     $condition: ModelPreliminaryInfoConditionInput
//   ) {
//     createPreliminaryInfo(input: $input, condition: $condition) {
//       id
//       name
//       dateOfBirth
//       hospital
//       typeOfCare
//       state
//       city
//       familySize
//       annualIncome
//       createdAt
//       updatedAt
//     }
//   }
// `;

export const createPreliminaryInfo = /* GraphQL */ `
  mutation CreatePreliminaryInfo(
    $input: CreatePreliminaryInfoInput!
    $condition: ModelPreliminaryInfoConditionInput
  ) {
    createPreliminaryInfo(input: $input, condition: $condition) {
      id
      userId
      name
      dateOfBirth
      hospital
      typeOfCare
      state
      city
      familySize
      annualIncome
      insurance
      createdAt
      updatedAt
    }
  }
`;

export const createBill = /* GraphQL */ `
  mutation CreateBill(
    $input: CreateBillInput!
    $condition: ModelBillConditionInput
  ) {
    createBill(input: $input, condition: $condition) {
      id
      userId
      preliminaryInfoId
      billName
      createdAt
      updatedAt
    }
  }
`;

export const createCase = /* GraphQL */ `
  mutation CreateCase(
    $input: CreateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    createCase(input: $input, condition: $condition) {
      id
      userId
      preliminaryInfoId
      status
      createdAt
      updatedAt
    }
  }
`;

export const updateCase = /* GraphQL */ `
  mutation UpdateCase(
    $input: UpdateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    updateCase(input: $input, condition: $condition) {
      id
      disputeLetterUrl
      disputeLetterDate
      updatedAt
    }
  }
`;
