import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  Button,
  Textarea,
  Flex,
  Box,
  Stack,
  Title,
} from "@mantine/core";
import { useSignup } from "./Signup_context"; // Make sure this import is correct

function Signup_services() {
  const navigate = useNavigate();
  const { signupData, updateSignupData } = useSignup();

  const handleButtonClick = () => {
    if (signupData.selectedServices?.length === 0) {
      alert("Please select a service.");
      return;
    }
    console.log("current signup data:", signupData);
    navigate("/signup_payment");
  };

  function handleServiceChange(service, isChecked) {
    const updatedServices = isChecked
      ? [...(signupData.selectedServices || []), service]
      : (signupData.selectedServices || []).filter((item) => item !== service);

    updateSignupData({ selectedServices: updatedServices });
  }

  function handleDescriptionChange(service, value) {
    const updatedDescriptions = {
      ...(signupData.serviceDescriptions || {}),
      [service]: value,
    };
    updateSignupData({ serviceDescriptions: updatedDescriptions });
  }

  return (
    <div>
      <Flex size={"sm"} align="center" justify="center" h={"100vh"}>
        <Box h="25em">
          <Stack>
            <Title> What type of services did you receive?</Title>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {["Childbirth", "Emergency room", "Surgery", "Other"].map(
                (service) => (
                  <div
                    key={service}
                    style={{ marginBottom: "10px", width: "80%" }}
                  >
                    <Checkbox
                      color="blue.9"
                      label={service}
                      checked={signupData.selectedServices?.includes(service)}
                      onChange={(event) =>
                        handleServiceChange(
                          service,
                          event.currentTarget.checked
                        )
                      }
                    />
                    {signupData.selectedServices?.includes(service) && (
                      <Textarea
                        placeholder="Please describe what happened in a few sentences"
                        value={signupData.serviceDescriptions?.[service] || ""}
                        onChange={(event) =>
                          handleDescriptionChange(
                            service,
                            event.currentTarget.value
                          )
                        }
                        autosize
                        minRows={5}
                        style={{
                          marginTop: "10px",
                          width: "100%",
                          fontSize: "16px",
                        }}
                      />
                    )}
                  </div>
                )
              )}

              <Button color="blue.9" mt="sm" onClick={handleButtonClick}>
                Next
              </Button>
            </div>
          </Stack>
        </Box>
      </Flex>
    </div>
  );
}

export default Signup_services;
