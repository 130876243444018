import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Select,
  Button,
  Title,
  Container,
  Group,
  Center,
  Box,
  Stack,
  Card,
  Flex,
} from "@mantine/core";
import { useSignup } from "./Signup_context";

function SignupHospital() {
  const { signupData, updateSignupData } = useSignup();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (!signupData.hospital) {
      alert("Please select a hospital.");
      return;
    }
    const user = true;
    if (user) {
      console.log("current signup data:", signupData);
      navigate("/signup_services");
    } else {
      alert("You need to be logged in.");
    }
  };

  return (
    <div>
      <Flex size={"sm"} align="center" justify="center" h={"100vh"}>
        <Box h="25em">
          <Stack>
            <Title> Which hospital did you visit?</Title>
            <Group justify="center">
              <Select
                placeholder="Choose your hospital"
                searchable
                nothingFound="No options"
                data={["Johns Hopkins", "Yale New Haven"]}
                value={signupData.hospital}
                onChange={(value) => updateSignupData({ hospital: value })}
                styles={{
                  // input: { color: "green" },
                  // label: { color: "green" },
                  root: { minWidth: "300px" },
                }}
              />

              <Button onClick={handleButtonClick} color="blue.9">
                Next
              </Button>
            </Group>
          </Stack>
        </Box>
      </Flex>
    </div>
  );
}

export default SignupHospital;
